<template>
	<v-dialog v-model="dialog" max-width="900">
		<template v-slot:activator="{ on: dialogActivator }">
			<v-tooltip top :z-index="200">
				<template v-slot:activator="{ on: tooltip }">
					<v-icon v-on="{ ...tooltip, ...dialogActivator }" small @click="openDialog">fas fa-map-marked-alt</v-icon>
				</template>
				<span>{{ $t('operationsvisitmap.tooltip') }}</span>
			</v-tooltip>
		</template>
		<operations-visit-map v-if="dialog" :visit="visit" v-on:close="closeDialog" />
	</v-dialog>
</template>

<script>
import OperationsVisitMap from './OperationsVisitMap.vue';

export default {
	name: 'OperationsVisitMapDialog',

	components: { OperationsVisitMap },

	props: {
		visit: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			dialog: false
		};
	},

	methods: {
		openDialog() {
			this.dialog = true;
		},
		closeDialog() {
			this.dialog = false;
		}
	}
};
</script>
