import FilterService from '@/services/filter.service.js';

export function getStatusStops(ctx) {
	let statusid = [];
	const stats = ctx.$store.getters.stopsStatusValues;
	if (stats) {
		Object.keys(stats).forEach((element) => {
			statusid.push(element);
		});
	}

	return statusid;
}

export default {
	data() {
		return {
			visitModelName: 'visit',
			subscriptionModelName: 'subscription',
			visits: [],
			subscriptions: [],
			visitsLoaded: true,
			currentDate: this.getCurrentDate(),
			searchText: '',
			zoomIn: 0,
			currentStatus: this.getStatus(),
			orderingBy: 'eta',
			currentPort: null
		};
	},
	computed: {
		onPuiModelsLoadedAndSaved() {
			return this.$store.state.modelsLoadedAndSaved;
		},
		filterCurrentDateMin() {
			if (this.zoomIn === 0) {
				return `${this.currentDate}T00:00:00Z`;
			}
			const previousDate = new Date(this.currentDate);
			previousDate.setDate(new Date(this.currentDate).getDate() - this.zoomIn);
			const dd = (previousDate.getDate() < 10 ? '0' : '') + previousDate.getDate();
			const MM = (previousDate.getMonth() + 1 < 10 ? '0' : '') + (previousDate.getMonth() + 1);
			const yyyy = previousDate.getFullYear();
			return `${yyyy}-${MM}-${dd}T00:00:00Z`;
		},
		filterCurrentDateMax() {
			if (this.zoomIn === 0) {
				return `${this.currentDate}T23:59:59Z`;
			}
			const nextDate = new Date(this.currentDate);
			nextDate.setDate(new Date(this.currentDate).getDate() + this.zoomIn);
			const dd = (nextDate.getDate() < 10 ? '0' : '') + nextDate.getDate();
			const MM = (nextDate.getMonth() + 1 < 10 ? '0' : '') + (nextDate.getMonth() + 1);
			const yyyy = nextDate.getFullYear();
			return `${yyyy}-${MM}-${dd}T23:59:59Z`;
		},
		currentDateMin() {
			const previousDate = new Date(this.currentDate);
			previousDate.setDate(new Date(this.currentDate).getDate() - this.zoomIn);
			const dd = (previousDate.getDate() < 10 ? '0' : '') + previousDate.getDate();
			const MM = (previousDate.getMonth() + 1 < 10 ? '0' : '') + (previousDate.getMonth() + 1);
			const yyyy = previousDate.getFullYear();
			return `${dd}/${MM}/${yyyy} 00:00`;
		},
		currentDateMax() {
			const nextDate = new Date(this.currentDate);
			nextDate.setDate(new Date(this.currentDate).getDate() + this.zoomIn);
			const dd = (nextDate.getDate() < 10 ? '0' : '') + nextDate.getDate();
			const MM = (nextDate.getMonth() + 1 < 10 ? '0' : '') + (nextDate.getMonth() + 1);
			const yyyy = nextDate.getFullYear();
			return `${dd}/${MM}/${yyyy} 23:59`;
		}
	},
	watch: {
		onPuiModelsLoadedAndSaved() {
			this.onReady();
		}
	},
	mounted() {
		if (this.$store.getters.getModelByName(this.visitModelName)) {
			this.onReady();
		}

		this.$puiEvents.$on('operations-toolbar_dateSelected', (newDateSelected) => {
			if (this.currentDate !== newDateSelected) {
				this.currentDate = newDateSelected;
				this.getSubscriptions();
				this.getSlotsShipCount();
				this.getVisits();
			}
		});
		this.$puiEvents.$on('operations-toolbar_zoomIn', (zoomIn) => {
			if (this.zoomIn !== zoomIn) {
				this.zoomIn = zoomIn;
				this.getSubscriptions();
				this.getSlotsShipCount();
				this.getVisits();
			}
		});
		this.$puiEvents.$on('operations-toolbar_portSelected', (portSelected) => {
			this.currentPort = portSelected;
			this.getSubscriptions();
			this.getSlotsShipCount();
			this.getVisits();
		});
		this.$puiEvents.$on('operations-toolbar_statusSelected', (status) => {
			const stats = this.$store.getters.visitsStatusValues;
			this.currentStatus = status;
		});
		this.$puiEvents.$on('operations-toolbar_searchingText', (searching) => {
			this.searchText = searching;
			if (this.searchVisits) {
				this.searchVisits();
			}
		});
		this.$puiEvents.$on('operations-toolbar_orderingBy', (ordering) => {
			this.orderingBy = ordering;
			this.getSubscriptions();
			this.getSlotsShipCount();
			this.getVisits();
		});
		this.$puiEvents.$on('operationscountdown_reload', () => {
			this.getSubscriptions();
			this.getSlotsShipCount();
			this.getVisits();
		});
	},
	destroyed() {
		this.$puiEvents.$off('operations-toolbar_dateSelected');
		this.$puiEvents.$off('operations-toolbar_portSelected');
		this.$puiEvents.$off('operations-toolbar_searchingText');
		this.$puiEvents.$off('operations-toolbar_orderingBy');
		this.$puiEvents.$off('operationscountdown_reload');
	},
	methods: {
		getStatusStops() {
			let statusid = [];
			const stats = this.$store.getters.stopsStatusValues;
			if (stats) {
				Object.keys(stats).forEach((element) => {
					statusid.push(element);
				});
			}

			return statusid;
		},
		onReady() {
			this.getEtcSafetyMargin();
			this.getEventsEnabled();
			this.getSubscriptions();
			this.getSlotsShipCount();
			this.getVisits();
		},
		getEtcSafetyMargin() {
			const puiVariableUrl = '/puivariable/getVariable/ETC_SAFETY_MARGIN';
			this.$puiRequests.getRequest(
				puiVariableUrl,
				null,
				(response) => {
					if (response.data) {
						this.$store.state.operations.etcSafetyMargin = response.data;
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		getEventsEnabled() {
			const puiVariableUrl = '/puivariable/getVariable/EVENTS_ENABLED';
			this.$puiRequests.getRequest(
				puiVariableUrl,
				null,
				(response) => {
					if (response.data) {
						const eventsEnabled = response.data;
						this.visitEvents = this.visitEvents.filter((event) => eventsEnabled.includes(event));
						this.stopEvents = this.stopEvents.filter((event) => eventsEnabled.includes(event));
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		getSubscriptions() {
			if (this.$store.getters.hasFunctionality('CREATE_SUBSCRIPTION')) {
				const subscriptionModel = this.$store.getters.getModelByName(this.subscriptionModelName);
				if (!subscriptionModel) {
					return;
				}

				const params = this.getSubscriptionParams();
				this.$puiRequests.postRequest(
					subscriptionModel.url.list,
					params,
					(response) => {
						if (response.data && response.data.data && response.data.data.length > 0) {
							for (let i = 0, length = response.data.data.length; i < length; i++) {
								const subscription = response.data.data[i];
								this.subscriptions.push(subscription.visit);
							}
						}
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					}
				);
			}
		},
		getSlotsShipCount() {
			const visitModel = this.$store.getters.getModelByName(this.visitModelName);
			if (!visitModel) {
				return;
			}
			if (window.localStorage.getItem('workingPortsId').includes(',') && this.currentPort === null) {
				return;
			}
			let currentPortId = this.currentPort !== null ? this.currentPort.portid : window.localStorage.getItem('workingPortsId');

			const params = {
				date: `${this.currentDate}T00:00:00Z`,
				portId: currentPortId
			};
			this.$puiRequests.getRequest(
				visitModel.url.getSlotShipCount,
				params,
				async (response) => {
					try {
						await this.fillSlotsFromResponse(response);
					} catch (error) {
						console.error(error);
					}
				},
				(error) => {
					console.error(error);
				}
			);
		},
		getVisits() {
			this.visitsLoaded = false;
			const visitModel = this.$store.getters.getModelByName(this.visitModelName);
			if (!visitModel) {
				return;
			}

			const params = this.getParams();
			this.$puiRequests.postRequest(
				visitModel.url.list,
				params,
				async (response) => {
					try {
						await this.fillVisitsFromResponse(response);
					} catch (error) {
						console.error(error);
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.visitsLoaded = true;
				}
			);
		},
		getSubscriptionParams() {
			const filter = {
				groupOp: 'and',
				groups: [],
				rules: [{ field: 'usr', op: 'eq', data: this.$store.getters.getUser }]
			};
			const params = {
				model: this.subscriptionModelName,
				page: 1,
				queryLang: this.$store.getters.getUserLanguage,
				rows: 200,
				filter: filter
			};
			return params;
		},
		getParams() {
			let currentPortId = this.currentPort !== null ? this.currentPort.portid : window.localStorage.getItem('workingPortsId');
			let oper = this.currentPort !== null ? 'eq' : 'in';
			const filter = {
				groupOp: 'and',
				groups: [
					{
						groupOp: 'or',
						groups: this.getFilterGroups(),
						rules: []
					}
				],
				rules: [{ field: 'portid', op: oper, data: currentPortId }]
			};
			const params = {
				model: 'visit',
				page: 1,
				queryLang: this.$store.getters.getUserLanguage,
				rows: 200,
				filter: filter
			};
			if (this.orderingBy) {
				params.order = [{ column: this.orderingBy, direction: 'asc' }];
			}

			return params;
		},
		getFilterGroups() {
			// FIXME: Only for commercial use, remove in future!!!
			if (this.$store.getters.getUser === 'huelva-operaciones') {
				return FilterService.getHuelvaFilterGroups(this.filterCurrentDateMin, this.filterCurrentDateMax);
			} else if (this.$store.getters.getUser === 'operations-malta') {
				return FilterService.getMaltaFilterGroups(this.filterCurrentDateMin, this.filterCurrentDateMax);
			}
			return FilterService.getDefaultFilterGroups(this.filterCurrentDateMin, this.filterCurrentDateMax);
		},
		getStatus() {
			let statusid = [];
			const stats = this.$store.getters.visitsStatusValues;
			const statusArray = Object.keys(stats).map((code) => ({
				code,
				...stats[code]
			}));
			statusArray.forEach((element) => {
				statusid.push(element.visitstatuscode);
			});
			return statusid;
		}
	}
};
