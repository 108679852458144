const filterService = {
	getDefaultFilterGroups: function (currentDateMin, currentDateMax) {
		const group1 = {
			groupOp: 'and',
			groups: [],
			rules: [
				{ field: 'ata', op: 'nu', data: null },
				{ field: 'atd', op: 'nu', data: null }
			]
		};

		group1.groups.push({
			groupOp: 'or',
			groups: [
				{
					groupOp: 'and',
					rules: [
						{ field: 'eta', op: 'le', data: currentDateMin },
						{ field: 'etd', op: 'ge', data: currentDateMin },
						{ field: 'etd', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'etd', op: 'ge', data: currentDateMax },
						{ field: 'eta', op: 'ge', data: currentDateMin },
						{ field: 'eta', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'eta', op: 'le', data: currentDateMin },
						{ field: 'etd', op: 'ge', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'eta', op: 'ge', data: currentDateMin },
						{ field: 'etd', op: 'le', data: currentDateMax }
					]
				}
			]
		});

		const group2 = {
			groupOp: 'and',
			groups: [],
			rules: [
				{ field: 'ata', op: 'nn', data: null },
				{ field: 'atd', op: 'nu', data: null }
			]
		};

		group2.groups.push({
			groupOp: 'or',
			groups: [
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'eta', op: 'le', data: currentDateMin },
								{ field: 'ata', op: 'le', data: currentDateMin }
							]
						}
					],
					rules: [
						{ field: 'etd', op: 'ge', data: currentDateMin },
						{ field: 'etd', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							groups: [
								{
									groupOp: 'and',
									rules: [
										{ field: 'eta', op: 'ge', data: currentDateMin },
										{ field: 'eta', op: 'le', data: currentDateMax }
									]
								},
								{
									groupOp: 'and',
									rules: [
										{ field: 'ata', op: 'ge', data: currentDateMin },
										{ field: 'ata', op: 'le', data: currentDateMax }
									]
								}
							]
						}
					],
					rules: [{ field: 'etd', op: 'ge', data: currentDateMax }]
				},
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'eta', op: 'le', data: currentDateMin },
								{ field: 'ata', op: 'le', data: currentDateMin }
							]
						}
					],
					rules: [{ field: 'etd', op: 'ge', data: currentDateMax }]
				},
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'eta', op: 'ge', data: currentDateMin },
								{ field: 'ata', op: 'ge', data: currentDateMin }
							]
						}
					],
					rules: [{ field: 'etd', op: 'le', data: currentDateMax }]
				}
			]
		});

		const group3 = {
			groupOp: 'and',
			groups: [],
			rules: [
				{ field: 'ata', op: 'nn', data: null },
				{ field: 'atd', op: 'nn', data: null }
			]
		};

		group3.groups.push({
			groupOp: 'or',
			groups: [
				{
					groupOp: 'and',
					rules: [
						{ field: 'ata', op: 'le', data: currentDateMin },
						{ field: 'atd', op: 'ge', data: currentDateMin },
						{ field: 'atd', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'atd', op: 'ge', data: currentDateMax },
						{ field: 'ata', op: 'ge', data: currentDateMin },
						{ field: 'ata', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'ata', op: 'le', data: currentDateMin },
						{ field: 'atd', op: 'ge', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'ata', op: 'ge', data: currentDateMin },
						{ field: 'atd', op: 'le', data: currentDateMax }
					]
				}
			]
		});

		return [group1, group2, group3];
	},

	getHuelvaFilterGroups: function (currentDateMin, currentDateMax) {
		const group1 = {
			groupOp: 'and',
			groups: [],
			rules: [
				{ field: 'ata', op: 'nu', data: null },
				{ field: 'atd', op: 'nu', data: null },
				{ field: 'from_port_code', op: 'eq', data: 'ESHUV' }
			]
		};

		group1.groups.push({
			groupOp: 'or',
			groups: [
				{
					groupOp: 'and',
					rules: [
						{ field: 'eta', op: 'le', data: currentDateMin },
						{ field: 'etd', op: 'ge', data: currentDateMin },
						{ field: 'etd', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'etd', op: 'ge', data: currentDateMax },
						{ field: 'eta', op: 'ge', data: currentDateMin },
						{ field: 'eta', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'eta', op: 'le', data: currentDateMin },
						{ field: 'etd', op: 'ge', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'eta', op: 'ge', data: currentDateMin },
						{ field: 'etd', op: 'le', data: currentDateMax }
					]
				}
			]
		});

		const group2 = {
			groupOp: 'and',
			groups: [],
			rules: [
				{ field: 'ata', op: 'nn', data: null },
				{ field: 'atd', op: 'nu', data: null },
				{ field: 'from_port_code', op: 'eq', data: 'ESHUV' }
			]
		};

		group2.groups.push({
			groupOp: 'or',
			groups: [
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'eta', op: 'le', data: currentDateMin },
								{ field: 'ata', op: 'le', data: currentDateMin }
							]
						}
					],
					rules: [
						{ field: 'etd', op: 'ge', data: currentDateMin },
						{ field: 'etd', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							groups: [
								{
									groupOp: 'and',
									rules: [
										{ field: 'eta', op: 'ge', data: currentDateMin },
										{ field: 'eta', op: 'le', data: currentDateMax }
									]
								},
								{
									groupOp: 'and',
									rules: [
										{ field: 'ata', op: 'ge', data: currentDateMin },
										{ field: 'ata', op: 'le', data: currentDateMax }
									]
								}
							]
						}
					],
					rules: [{ field: 'etd', op: 'ge', data: currentDateMax }]
				},
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'eta', op: 'le', data: currentDateMin },
								{ field: 'ata', op: 'le', data: currentDateMin }
							]
						}
					],
					rules: [{ field: 'etd', op: 'ge', data: currentDateMax }]
				},
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'eta', op: 'ge', data: currentDateMin },
								{ field: 'ata', op: 'ge', data: currentDateMin }
							]
						}
					],
					rules: [{ field: 'etd', op: 'le', data: currentDateMax }]
				}
			]
		});

		const group3 = {
			groupOp: 'and',
			groups: [],
			rules: [
				{ field: 'ata', op: 'nn', data: null },
				{ field: 'atd', op: 'nn', data: null },
				{ field: 'from_port_code', op: 'eq', data: 'ESHUV' }
			]
		};

		group3.groups.push({
			groupOp: 'or',
			groups: [
				{
					groupOp: 'and',
					rules: [
						{ field: 'ata', op: 'le', data: currentDateMin },
						{ field: 'atd', op: 'ge', data: currentDateMin },
						{ field: 'atd', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'atd', op: 'ge', data: currentDateMax },
						{ field: 'ata', op: 'ge', data: currentDateMin },
						{ field: 'ata', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'ata', op: 'le', data: currentDateMin },
						{ field: 'atd', op: 'ge', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'ata', op: 'ge', data: currentDateMin },
						{ field: 'atd', op: 'le', data: currentDateMax }
					]
				}
			]
		});

		return [group1, group2, group3];
	},

	getMaltaFilterGroups: function (currentDateMin, currentDateMax) {
		const group1 = {
			groupOp: 'and',
			groups: [],
			rules: [
				{ field: 'ata', op: 'nu', data: null },
				{ field: 'atd', op: 'nu', data: null },
				{ field: 'to_port_code', op: 'eq', data: 'MTMAR' }
			]
		};

		group1.groups.push({
			groupOp: 'or',
			groups: [
				{
					groupOp: 'and',
					rules: [
						{ field: 'eta', op: 'le', data: currentDateMin },
						{ field: 'etd', op: 'ge', data: currentDateMin },
						{ field: 'etd', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'etd', op: 'ge', data: currentDateMax },
						{ field: 'eta', op: 'ge', data: currentDateMin },
						{ field: 'eta', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'eta', op: 'le', data: currentDateMin },
						{ field: 'etd', op: 'ge', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'eta', op: 'ge', data: currentDateMin },
						{ field: 'etd', op: 'le', data: currentDateMax }
					]
				}
			]
		});

		const group2 = {
			groupOp: 'and',
			groups: [],
			rules: [
				{ field: 'ata', op: 'nn', data: null },
				{ field: 'atd', op: 'nu', data: null },
				{ field: 'to_port_code', op: 'eq', data: 'MTMAR' }
			]
		};

		group2.groups.push({
			groupOp: 'or',
			groups: [
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'eta', op: 'le', data: currentDateMin },
								{ field: 'ata', op: 'le', data: currentDateMin }
							]
						}
					],
					rules: [
						{ field: 'etd', op: 'ge', data: currentDateMin },
						{ field: 'etd', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							groups: [
								{
									groupOp: 'and',
									rules: [
										{ field: 'eta', op: 'ge', data: currentDateMin },
										{ field: 'eta', op: 'le', data: currentDateMax }
									]
								},
								{
									groupOp: 'and',
									rules: [
										{ field: 'ata', op: 'ge', data: currentDateMin },
										{ field: 'ata', op: 'le', data: currentDateMax }
									]
								}
							]
						}
					],
					rules: [{ field: 'etd', op: 'ge', data: currentDateMax }]
				},
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'eta', op: 'le', data: currentDateMin },
								{ field: 'ata', op: 'le', data: currentDateMin }
							]
						}
					],
					rules: [{ field: 'etd', op: 'ge', data: currentDateMax }]
				},
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'eta', op: 'ge', data: currentDateMin },
								{ field: 'ata', op: 'ge', data: currentDateMin }
							]
						}
					],
					rules: [{ field: 'etd', op: 'le', data: currentDateMax }]
				}
			]
		});

		const group3 = {
			groupOp: 'and',
			groups: [],
			rules: [
				{ field: 'ata', op: 'nn', data: null },
				{ field: 'atd', op: 'nn', data: null },
				{ field: 'to_port_code', op: 'eq', data: 'MTMAR' }
			]
		};

		group3.groups.push({
			groupOp: 'or',
			groups: [
				{
					groupOp: 'and',
					rules: [
						{ field: 'ata', op: 'le', data: currentDateMin },
						{ field: 'atd', op: 'ge', data: currentDateMin },
						{ field: 'atd', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'atd', op: 'ge', data: currentDateMax },
						{ field: 'ata', op: 'ge', data: currentDateMin },
						{ field: 'ata', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'ata', op: 'le', data: currentDateMin },
						{ field: 'atd', op: 'ge', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'ata', op: 'ge', data: currentDateMin },
						{ field: 'atd', op: 'le', data: currentDateMax }
					]
				}
			]
		});

		return [group1, group2, group3];
	},

	getBerthPlannerFilterGroups: function (currentDateMin, currentDateMax, locationIds, portId, statusArray) {
		const group1 = {
			groupOp: 'and',
			groups: [
				{
					groupOp: 'or',
					groups: [
						{
							groupOp: 'and',
							rules: [
								{ field: 'eta_planner', op: 'le', data: currentDateMin },
								{ field: 'etd_planner', op: 'ge', data: currentDateMax }
							]
						},
						{
							groupOp: 'and',
							rules: [
								{ field: 'eta_planner', op: 'ge', data: currentDateMin },
								{ field: 'eta_planner', op: 'le', data: currentDateMax }
							]
						},
						{
							groupOp: 'and',
							rules: [
								{ field: 'etd_planner', op: 'ge', data: currentDateMin },
								{ field: 'etd_planner', op: 'le', data: currentDateMax }
							]
						}
					]
				}
			],
			rules: [
				{ field: 'berth_id', op: 'in', data: locationIds },
				{ field: 'port_id', op: 'eq', data: portId },
				{ field: 'statusid', op: 'in', data: statusArray }
			]
		};

		return [group1];
	},

	getBerthPlannerBookingFilterGroups: function (currentDateMin, currentDateMax, locationIds, portId, statusArray) {
		const group1 = {
			groupOp: 'and',
			groups: [
				{
					groupOp: 'or',
					groups: [
						{
							groupOp: 'and',
							rules: [
								{ field: 'eta', op: 'le', data: currentDateMin },
								{ field: 'etd', op: 'ge', data: currentDateMax }
							]
						},
						{
							groupOp: 'and',
							rules: [
								{ field: 'eta', op: 'ge', data: currentDateMin },
								{ field: 'eta', op: 'le', data: currentDateMax }
							]
						},
						{
							groupOp: 'and',
							rules: [
								{ field: 'etd', op: 'ge', data: currentDateMin },
								{ field: 'etd', op: 'le', data: currentDateMax }
							]
						}
					]
				}
			],
			rules: [
				{ field: 'berth', op: 'in', data: locationIds },
				{ field: 'port', op: 'eq', data: portId }
			]
		};

		return [group1];
	},

	getAdvancedBerthPlannerFilterGroups: function (currentDateMin, currentDateMax, locationIds) {
		const group1 = {
			groupOp: 'and',
			groups: [],
			rules: [
				{ field: 'ata', op: 'nu', data: null },
				{ field: 'atd', op: 'nu', data: null },
				{ field: 'berth_id', op: 'in', data: locationIds },
				{ field: 'status', op: 'ni', data: ['REJECTED', 'CANCELLED'] }
			]
		};

		group1.groups.push({
			groupOp: 'or',
			groups: [
				{
					groupOp: 'and',
					rules: [
						{ field: 'eta', op: 'le', data: currentDateMin },
						{ field: 'etd', op: 'ge', data: currentDateMin },
						{ field: 'etd', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'etd', op: 'ge', data: currentDateMax },
						{ field: 'eta', op: 'ge', data: currentDateMin },
						{ field: 'eta', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'eta', op: 'le', data: currentDateMin },
						{ field: 'etd', op: 'ge', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'eta', op: 'ge', data: currentDateMin },
						{ field: 'etd', op: 'le', data: currentDateMax }
					]
				}
			]
		});

		const group2 = {
			groupOp: 'and',
			groups: [],
			rules: [
				{ field: 'ata', op: 'nn', data: null },
				{ field: 'atd', op: 'nu', data: null },
				{ field: 'berth_id', op: 'in', data: locationIds },
				{ field: 'status', op: 'ni', data: ['REJECTED', 'CANCELLED'] }
			]
		};

		group2.groups.push({
			groupOp: 'or',
			groups: [
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'eta', op: 'le', data: currentDateMin },
								{ field: 'ata', op: 'le', data: currentDateMin }
							]
						}
					],
					rules: [
						{ field: 'etd', op: 'ge', data: currentDateMin },
						{ field: 'etd', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							groups: [
								{
									groupOp: 'and',
									rules: [
										{ field: 'eta', op: 'ge', data: currentDateMin },
										{ field: 'eta', op: 'le', data: currentDateMax }
									]
								},
								{
									groupOp: 'and',
									rules: [
										{ field: 'ata', op: 'ge', data: currentDateMin },
										{ field: 'ata', op: 'le', data: currentDateMax }
									]
								}
							]
						}
					],
					rules: [{ field: 'etd', op: 'ge', data: currentDateMax }]
				},
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'eta', op: 'le', data: currentDateMin },
								{ field: 'ata', op: 'le', data: currentDateMin }
							]
						}
					],
					rules: [{ field: 'etd', op: 'ge', data: currentDateMax }]
				},
				{
					groupOp: 'and',
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'eta', op: 'ge', data: currentDateMin },
								{ field: 'ata', op: 'ge', data: currentDateMin }
							]
						}
					],
					rules: [{ field: 'etd', op: 'le', data: currentDateMax }]
				}
			]
		});

		const group3 = {
			groupOp: 'and',
			groups: [],
			rules: [
				{ field: 'ata', op: 'nn', data: null },
				{ field: 'atd', op: 'nn', data: null },
				{ field: 'berth_id', op: 'in', data: locationIds },
				{ field: 'status', op: 'ni', data: ['REJECTED', 'CANCELLED'] }
			]
		};

		group3.groups.push({
			groupOp: 'or',
			groups: [
				{
					groupOp: 'and',
					rules: [
						{ field: 'ata', op: 'le', data: currentDateMin },
						{ field: 'atd', op: 'ge', data: currentDateMin },
						{ field: 'atd', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'atd', op: 'ge', data: currentDateMax },
						{ field: 'ata', op: 'ge', data: currentDateMin },
						{ field: 'ata', op: 'le', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'ata', op: 'le', data: currentDateMin },
						{ field: 'atd', op: 'ge', data: currentDateMax }
					]
				},
				{
					groupOp: 'and',
					rules: [
						{ field: 'ata', op: 'ge', data: currentDateMin },
						{ field: 'atd', op: 'le', data: currentDateMax }
					]
				}
			]
		});

		return [group1, group2, group3];
	}
};

export default filterService;
