<template>
	<div class="operations-visit-detail">
		<v-layout class="pt-3 pl-3 pr-3">
			<v-flex xs12>
				<v-layout align-center fill-height>
					<v-flex xs7>
						<span class="operations-visit-detail__title">{{ vesselname + ' - ' + servicename }}</span>
					</v-flex>
					<v-spacer></v-spacer>

					<v-flex xs1>
						<v-btn icon ripple @click="closeDialog()">
							<v-icon>far fa-times</v-icon>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
		<v-layout class="pl-3 pr-3" ref="dynamicTable">
			<v-flex xs12>
				<v-container fluid class="resume">
					<table>
						<tr class="resume_title">
							<th>{{ $t('stop.stopStatus') }}</th>
							<th>{{ $t('grid.technicalnauticalservice.fis') }}</th>
							<th>{{ $t('grid.technicalnauticalservice.ffs') }}</th>
						</tr>
						<tr class="resume_subtitle">
							<td>{{ getStatus(service) }}</td>
							<td>{{ getEta(service) }}</td>
							<td>{{ getEtd(service) }}</td>
						</tr>
						<tr class="resume_subtitle">
							<th>{{ $t('grid.technicalnauticalservice.ata') }}</th>
							<th>{{ $t('grid.technicalnauticalservice.atd') }}</th>
						</tr>
						<tr>
							<td>{{ getAta(service) }}</td>
							<td>{{ getAtd(service) }}</td>
						</tr>

						<tr v-if="service.servicetypeid == 1" class="resume_subtitle">
							<th>{{ $t('form.pilotage.pilotname') }}</th>
							<th>{{ $t('form.pilotage.maneuvertype') }}</th>
						</tr>
						<tr v-if="service.servicetypeid == 1" class="resume_subtitle">
							<td>{{ service.pilot }}</td>
							<td>{{ service.maneouvrepilotage }}</td>
						</tr>

						<tr v-if="service.servicetypeid == 2" class="resume_subtitle">
							<th>{{ $t('form.towage.maneuvertype') }}</th>
						</tr>
						<tr v-if="service.servicetypeid == 2" class="resume_subtitle">
							<td>{{ service.maneouvretowage }}</td>
						</tr>

						<tr v-if="service.servicetypeid == 3" class="resume_subtitle">
							<th>{{ $t('form.mooring.type') }}</th>
							<th>{{ $t('form.mooring.berth') }}</th>
							<th>{{ $t('form.mooring.bollardini') }}</th>
						</tr>
						<tr v-if="service.servicetypeid == 3" class="resume_subtitle">
							<td>{{ moorigntype }}</td>
							<td>{{ service.muelle }}</td>
							<td>{{ service.bolini }}</td>
						</tr>
						<tr v-if="service.servicetypeid == 3" class="resume_subtitle">
							<th>{{ $t('form.mooring.bollardend') }}</th>
							<th>{{ $t('form.mooring.numropes') }}</th>
							<th>{{ $t('form.mooring.nummoorers') }}</th>
						</tr>
						<tr v-if="service.servicetypeid == 3" class="resume_subtitle">
							<td>{{ service.bolend }}</td>
							<td>{{ service.numropes }}</td>
							<td>{{ service.nummoorers }}</td>
						</tr>
					</table>
				</v-container>
			</v-flex>
		</v-layout>
		<v-layout>
			<v-flex xs12>
				<v-tabs slider-color="#00A9D8">
					<v-tab>{{ $t('operationsvisitdetail.tabs.overview') }}</v-tab>

					<v-tab-item>
						<v-divider />
					</v-tab-item>
				</v-tabs>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import DifferenceMixin from '@/mixins/HHmmDifferenceMixin';
import OperationsChat from '@/components/chat/OperationsChat';

export default {
	mixins: [DifferenceMixin],
	components: { OperationsChat },
	props: {
		service: {
			type: Object,
			required: true
		},
		visit: {
			type: Object,
			required: true
		}
	},
	data() {
		return {};
	},
	computed: {
		vesselname() {
			return this.service.vesselname;
		},
		statusClass() {
			return this.service.statusid.toLowerCase();
		},
		status() {
			return this.$t(`visit.status.${this.service.status.toLowerCase()}`);
		},
		servicename() {
			return this.$t(`operationsservicedetail.servicetype.${this.service.servicetypename.toLowerCase()}`);
		},
		moorigntype() {
			return this.$t(`operationsservicedetail.moorigntype.${this.service.moorigntype.toLowerCase()}`);
		}
	},
	mounted() {},
	methods: {
		getEta(service) {
			return this.$dateTimeUtils.getLocalFormattedDate(service.eta, 'DD/MM/YYYY HH:mm');
		},
		getEtd(service) {
			return this.$dateTimeUtils.getLocalFormattedDate(service.etd, 'DD/MM/YYYY HH:mm');
		},
		getAta(service) {
			return this.$dateTimeUtils.getLocalFormattedDate(service.ata, 'DD/MM/YYYY HH:mm');
		},
		getAtd(service) {
			return this.$dateTimeUtils.getLocalFormattedDate(service.atd, 'DD/MM/YYYY HH:mm');
		},
		getStatus(service) {
			return service.servicestatusname;
		},
		closeDialog() {
			this.$puiEvents.$emit('operations-navigationDrawer-closed');
		}
	}
};
</script>

<style lang="postcss">
.operations-visit-detail {
	&__title {
		font-size: 24px;
		color: #1a2a4b;
	}
	& .v-tabs__div {
		font-size: 15px;
		color: #1a2a4b;
	}
	&__status {
		background-color: rgba(246, 111, 14, 0.1);
		border-radius: 2px;
		font-weight: 500;
		font-size: 15px;
		text-transform: capitalize;
		margin-left: 2px;
		margin-right: 2px;
		& .in {
			color: #f66f0e;
		}
		& .an {
			color: #cb2b16;
		}
		& .fi {
			color: #f66f0e;
		}
	}
	& table {
		width: 100%;
	}
	& td {
		text-align: center;
		padding: 2px 0;
	}
	& .resume {
		background-color: #f5f7f7;
		border-radius: 8px;
		font-size: 14px;
		&_title {
			font-weight: 500;
			font-size: 14px;
			color: #303e5a;
		}
		&_subtitle {
			font-weight: normal;
			font-size: 14px;
			color: #464f63;
			cursor: pointer;
		}
	}
	& .selected {
		background-color: rgba(246, 111, 14, 0.1);
	}
	& .performance {
		font-weight: bold;
		font-size: 12px;
		color: #ffffff;
		padding: 2px;
		border-radius: 2px;
		&_positive {
			background-color: #61ad4e;
		}
		&_negative {
			background-color: #cb2b16;
		}
	}
	& .v-text-field--outline {
		& .v-select__selections {
			padding-top: 0px !important;
		}
		& .v-input__append-inner {
			margin-top: 8px !important;
		}
	}
	&-table {
		width: 100%;

		& tr:nth-child(even) {
			background-color: #f5f7f7;
		}
		& td {
			text-align: center;
			padding: 8px 0;
		}
		&__header {
			font-weight: bold;
			font-size: 13px;
			color: #6f7480;
		}
		&__content {
			font-weight: normal;
			font-size: 14px;
			color: #464f63;
		}
	}
}
</style>
