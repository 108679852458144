<template>
	<div class="mapzoomscontrol rounded elevation-1">
		<v-btn text class="btnzoom" @click="refresh">
			<i class="fas fa-sync-alt"></i>
		</v-btn>

		<v-btn text class="btnzoom" @click="zoom = zoom + 1">
			<i class="far fa-plus"></i>
		</v-btn>

		<v-btn text class="btnlevel">{{ zoom }}</v-btn>

		<v-btn text class="btnzoom" @click="zoom = zoom - 1">
			<i class="far fa-minus"></i>
		</v-btn>
	</div>
</template>

<script>
import ol2map from './ol2map';
import {
	MIN_ZOOM,
	MAX_ZOOM,
	INITIAL_ZOOM,
	INITIAL_ZOOM_VISITS,
	MAP_AIS_ID,
	MAP_PERIODS_ID,
	MAP_VISIT
} from '@/components/operations/map/sections/map/subcomponents/mapVariables';

export default {
	name: 'mapzoomscontrol',

	props: {
		currentmap: {
			type: String,
			required: true
		}
	},

	methods: {
		refresh() {
			this.$puiEvents.$emit('map:updated');
			this.$puiEvents.$emit('map:refreshprogress');
		}
	},

	computed: {
		zoom: {
			get() {
				if (this.currentmap == MAP_AIS_ID) {
					if (!this.$store.getters.zoomAIS) {
						return INITIAL_ZOOM;
					}

					return this.$store.getters.zoomAIS | 0; // El zero es para devolver enteros.
				} else if (this.currentmap == MAP_PERIODS_ID) {
					if (!this.$store.getters.zoomPeriods) {
						return INITIAL_ZOOM;
					}

					return this.$store.getters.zoomPeriods | 0; // El zero es para devolver enteros.
				} else {
					if (!this.$store.getters.zoomVisits) {
						return INITIAL_ZOOM_VISITS;
					}

					return this.$store.getters.zoomVisits | 0; // El zero es para devolver enteros.
				}
			},
			set(value) {
				if (value > MAX_ZOOM || value < MIN_ZOOM) {
					return;
				}

				if (this.currentmap == MAP_AIS_ID && ol2map.map) {
					ol2map.setZoom(value);
					this.$store.commit('setMapZoomAIS', value);
				}

				if (this.currentmap == MAP_PERIODS_ID && ol2map.map) {
					ol2map.setZoom(value);
					this.$store.commit('setMapZoomPeriods', value);
				}

				if (this.currentmap == MAP_VISIT && ol2map.map) {
					ol2map.setZoom(value);
					this.$store.commit('setMapZoomVisits', value);
				}
			}
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../../styles/app-variables.pcss';
.mapzoomscontrol {
	position: absolute;
	bottom: 1.5rem;
	z-index: 2;
	right: 1rem;
	height: 32px;
	background-color: var(--negative);
	opacity: 0.9 !important;

	& .btnzoom {
		margin: 0px;
		width: 32px;
		min-width: 32px;
		height: 32px;
		background-color: var(--negative);
	}
	& .btnlevel {
		margin: 0px;
		width: 34px;
		min-width: 34px;
		height: 32px;
		background-color: var(--negative);
		cursor: auto !important;
		font-family: var(--font-family);

		&:hover {
			background: var(--negative) !important;
		}
		&:focus {
			background: var(--negative) !important;
		}
	}
}
</style>
