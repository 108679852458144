import Map from 'ol/Map';
import * as olProj from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import WKT from 'ol/format/WKT';
import {
	AIS_VESSEL_LAYER_ID,
	STOP_ACCEPTED_LAYER_ID,
	STOP_PLANNED_LAYER_ID,
	STOP_AUTHORIZED_LAYER_ID,
	STOP_CONFIRMED_LAYER_ID,
	STOP_INITIATED_LAYER_ID,
	STOP_INSTANT_LAYER_ID
} from '@/components/operations/map/constants/layers';

export default {
	map: null,

	init(mapProps) {
		this.map = new Map(mapProps);
	},

	getMapInstance() {
		return this.map;
	},

	getAllLayers() {
		return this.map.getAllLayers();
	},

	getAllVectorLayers() {
		const vectorialLayers = [];
		this.map.getLayers().forEach((lyr) => {
			// Comprueba si la capa es de tipo vectorial
			if (lyr instanceof VectorLayer) {
				vectorialLayers.push(lyr);
			}
		});
		return vectorialLayers;
	},

	getZoom() {
		return this.map.getView().getZoom();
	},

	getMapExtent() {
		return olProj.transformExtent(this.map.getView().calculateExtent(this.map.getSize()), 'EPSG:3857', 'EPSG:4326');
	},

	getLayerById(id) {
		var layers = this.getAllLayers();
		for (var i = 0; i < layers.length; i++) {
			var layer = layers[i];
			var layerId = layer.values_.id;
			if (layerId === id) {
				return layer;
			}
		}
		return;
	},

	getBerthById(idLayer, berthid) {
		var layers = this.getAllLayers();
		for (var i = 0; i < layers.length; i++) {
			var layer = layers[i];
			var layerId = layer.values_.id;
			if (layerId === idLayer) {
				return layer.getSource()
					? layer
							.getSource()
							.getFeatures()
							.find((feature) => feature.get('id') === berthid)
					: [];
			}
		}
		return [];
	},

	getBollardsByCode(id, berthid, bolcode, portid, dockid) {
		var layers = this.getAllLayers();
		for (var i = 0; i < layers.length; i++) {
			var layer = layers[i];
			var layerId = layer.values_.id;
			if (layerId === id) {
				return layer.getSource()
					? layer
							.getSource()
							.getFeatures()
							.find((feature) =>
								dockid != null
									? feature.get('dockid') === dockid && feature.get('bolcode') === bolcode && feature.get('portid') === portid
									: feature.get('berthid') === berthid && feature.get('bolcode') === bolcode && feature.get('portid') === portid
							)
					: [];
			}
		}
		return [];
	},

	getFeaturesLayerById(id) {
		var layers = this.getAllLayers();
		for (var i = 0; i < layers.length; i++) {
			var layer = layers[i];
			var layerId = layer.values_.id;
			if (layerId === id) {
				return layer.getSource() ? layer.getSource().getFeatures() : [];
			}
		}
		return [];
	},

	setVisibleFeatureByVesselText(searchingtext, statusCodes = null, viewCodes = null) {
		const searchlayers = [
			STOP_ACCEPTED_LAYER_ID,
			STOP_PLANNED_LAYER_ID,
			STOP_AUTHORIZED_LAYER_ID,
			STOP_CONFIRMED_LAYER_ID,
			STOP_INITIATED_LAYER_ID,
			STOP_INSTANT_LAYER_ID
		];
		const filteredLayers = this.getAllVectorLayers().filter((layer) => !!layer);

		for (const layer of filteredLayers) {
			var layerId = layer.values_.id;
			if (searchlayers.includes(layerId)) {
				const features = layer.getSource().getFeatures();
				for (const feature of features) {
					const vesselName = feature.getProperties().vesselname;
					const vesselTypeDescription = feature.getProperties().vesseltypedescription;
					const vesselMMSI = feature.getProperties().mmsi;
					const statusId = feature.getProperties().statusid;

					const searchTextLower = (searchingtext ?? '').toLowerCase();
					const isVesselNameMatch = (vesselName ?? '').toLowerCase().includes(searchTextLower);
					const isVesselTypeMatch = (vesselTypeDescription ?? '').toLowerCase().includes(searchTextLower);
					const isMMSIMatch = (vesselMMSI ?? '').toString().toLowerCase().includes(searchTextLower);

					const isVisible = isVesselNameMatch || isVesselTypeMatch || isMMSIMatch;
					if (statusCodes && statusCodes.length > 0) {
						const isStatusMatch = statusCodes.includes(statusId) || (viewCodes && viewCodes.includes(statusId));
						feature.set('featureVisible', isVisible && isStatusMatch);
					} else {
						feature.set('featureVisible', isVisible);
					}
				}
			}
		}
	},

	setVisibleFeatureByFilterAIS(
		searchingtext,
		isFilterAisVisitLayerActive,
		isFilterServiceLayerActive,
		isFilterOtherVesselLayerActive,
		isFilterHighFrequencyLayerActive
	) {
		var layer = this.getLayerById(AIS_VESSEL_LAYER_ID);
		const features = layer.getSource().getFeatures();

		for (const feature of features) {
			const vesselName = feature.getProperties().vesselname;
			const vesselTypeDescription = feature.getProperties().vesseltypedescription;
			const vesselMMSI = feature.getProperties().mmsi;

			const searchTextLower = (searchingtext ?? '').toLowerCase();
			const isVesselNameMatch = (vesselName ?? '').toLowerCase().includes(searchTextLower);
			const isVesselTypeMatch = (vesselTypeDescription ?? '').toLowerCase().includes(searchTextLower);
			const isMMSIMatch = (vesselMMSI ?? '').toString().toLowerCase().includes(searchTextLower);

			let isVisible = isVesselNameMatch || isVesselTypeMatch || isMMSIMatch;

			if (feature.get('otherVessel')) {
				isVisible = isFilterOtherVesselLayerActive && isVisible;
			}
			if (feature.get('isService')) {
				isVisible = isFilterServiceLayerActive && isVisible;
			} else {
				if (feature.get('portCallNumber') && feature.get('highFrequency')) {
					isVisible = (isFilterAisVisitLayerActive || isFilterHighFrequencyLayerActive) && isVisible;
				} else if (feature.get('portCallNumber')) {
					isVisible = isFilterAisVisitLayerActive && isVisible;
				} else if (feature.get('highFrequency')) {
					isVisible = isFilterHighFrequencyLayerActive && isVisible;
				}
			}
			feature.set('featureVisible', isVisible);
		}
	},

	getFeatureAisBymmsi(mmsi) {
		var layer = this.getLayerById(AIS_VESSEL_LAYER_ID);

		let theAis =
			layer &&
			layer
				.getSource()
				.getFeatures()
				.find((feature) => feature.get('mmsi') === Number(mmsi));

		if (theAis) {
			return theAis;
		}
		return null;
	},

	getFeatureAisByImo(imo) {
		var layer = this.getLayerById(AIS_VESSEL_LAYER_ID);

		let theAis =
			layer &&
			layer
				.getSource()
				.getFeatures()
				.find((feature) => feature.get('imo') === imo);

		if (theAis) {
			return theAis;
		}
		return null;
	},

	createEmptyVectorLayer(id) {
		const vectorSource = new VectorSource({
			features: []
		});
		const vectorLayer = new VectorLayer({
			source: vectorSource,
			properties: {
				id: id
			}
		});
		return vectorLayer;
	},

	createVectorLayerFeatures(id, features, style, opts) {
		const vectorSource = new VectorSource({
			features: features
		});
		const vectorLayer = new VectorLayer({
			source: vectorSource,
			style: style,
			properties: {
				id: id
			},
			...opts
		});
		return vectorLayer;
	},

	processFeaturesFromWktToFeatures(featuresWkt, type) {
		const format = new WKT();
		var featuresGeomery = featuresWkt
			.map((featureWkt) => {
				try {
					const feat = format.readFeature(featureWkt.thegeom, {
						dataProjection: 'EPSG:4326',
						featureProjection: 'EPSG:3857'
					});
					feat.setProperties(featureWkt);
					feat.setId(featureWkt.id);
					feat.type = type;
					return feat;
				} catch (e) {
					console.error('Feature no añadido: ' + featureWkt.id);
				}
			})
			.filter((feat) => !feat === false);

		return featuresGeomery;
	},

	addFeaturesToVectorLayer(layerId, features) {
		if (this.hasLayer(layerId)) {
			var layer = this.getLayerById(layerId);
			layer.getSource().addFeatures(features);
		}
	},

	addLayer(layer) {
		if (!layer.values_.id) {
			throw new Error(`[ol2map.addLayer] layer id must defined. Received "${layer.id}" instead`);
		}
		this.map.addLayer(layer);
	},

	removeLayer(layer) {
		if (this.hasLayer(layer.values_.id)) {
			this.map.removeLayer(layer);
		}
	},

	removeLayerById(layerId) {
		if (this.hasLayer(layerId)) {
			var layer = this.getLayerById(layerId);
			this.map.removeLayer(layer);
		}
	},

	insertLayerAtPosition(position, layer) {
		this.map.getLayers().insertAt(position, layer);
	},

	hasLayer(id) {
		return !!this.getAllLayers().find((layer) => layer.get('id') == id);
	},

	refreshLayer(id) {
		if (this.hasLayer(id)) {
			var layer = this.getLayerById(id);
			layer.getSource().refresh();
		}
	},

	setVisibility(id, visible) {
		if (this.hasLayer(id)) {
			var layer = this.getLayerById(id);
			layer.setVisible(visible);
		}
	},

	setZoom(zoom) {
		this.map.getView().setZoom(zoom);
	},

	addOverlay(overlay) {
		if (!overlay.id) {
			throw new Error(`[ol2map.overlay] layer id must defined. Received "${overlay.id}" instead`);
		}
		this.map.addOverlay(overlay);
	},

	zoomToExtent(extent) {
		if (extent) {
			this.map.getView().fit(extent);
		} else {
			console.log('Error al hacer zoom a la extensión, la extension es nula');
		}
	},

	centerInCoordinates(coordinates, zoom) {
		this.map.getView().animate({
			center: [coordinates[0], coordinates[1]],
			duration: 300,
			zoom
		});
	}
};
