import store from '@/store/store';
export default {
	/**
	 * Obtener el color de un elemento de operations
	 * @param {Feature} feature
	 */
	getColorOperations: function (feature) {
		const thematicColorPalete = store.getters.stopsOperationsValues;
		if (feature.get('isPilot')) {
			return thematicColorPalete['isPilot'].color;
		}

		if (feature.get('isTug')) {
			return thematicColorPalete['isTug'].color;
		}

		if (feature.get('isService')) {
			return thematicColorPalete['otherVesselServices'].color;
		}

		if (feature.get('portCallNumber')) {
			return thematicColorPalete['vesselvisit'].color;
		}

		if (feature.get('otherVesselServices')) {
			return thematicColorPalete['otherVesselServices'].color;
		}

		if (feature.get('highFrequency')) {
			return thematicColorPalete['highFrequency'].color;
		}

		return thematicColorPalete['otherVessel'].color;
	},

	/**
	 * Obtener el color de borde de un elemento de operations
	 * @param {Feature} feature
	 */
	getColorOutlineOperations: function (feature) {
		const thematicColorPalete = store.getters.stopsOperationsValues;
		if (feature.get('isPilot')) {
			return thematicColorPalete['isPilot'].coloroutline;
		}

		if (feature.get('isTug')) {
			return thematicColorPalete['isTug'].coloroutline;
		}

		if (feature.get('isService')) {
			return thematicColorPalete['otherVesselServices'].coloroutline;
		}

		if (feature.get('portCallNumber')) {
			return thematicColorPalete['vesselvisit'].coloroutline;
		}

		if (feature.get('otherVesselServices')) {
			return thematicColorPalete['otherVesselServices'].coloroutline;
		}

		if (feature.get('highFrequency')) {
			return thematicColorPalete['highFrequency'].coloroutline;
		}

		return thematicColorPalete['otherVessel'].coloroutline;
	},
	/**
	 * Obtener el color de un elemento de operations para base de datos
	 * @param {Json} stop
	 */
	getColorOperationsStop: function (stop) {
		const thematicColorPalete = store.getters.stopsOperationsValues;
		if (stop.isPilot) {
			return thematicColorPalete['isPilot'].color;
		}

		if (stop.isTug) {
			return thematicColorPalete['isTug'].color;
		}

		if (stop.isservice == 1) {
			return thematicColorPalete['otherVesselServices'].color;
		}

		return thematicColorPalete['vesselvisit'].color;
	},

	/**
	 * Obtener el color de un elemento de operations para base de datos
	 * @param {Json} stop
	 */
	getColorOutlineOperationsStop: function (stop) {
		const thematicColorPalete = store.getters.stopsOperationsValues;
		if (stop.isPilot) {
			return thematicColorPalete['isPilot'].coloroutline;
		}

		if (stop.isTug) {
			return thematicColorPalete['isTug'].coloroutline;
		}

		if (stop.isservice == 1) {
			return thematicColorPalete['otherVesselServices'].coloroutline;
		}

		return thematicColorPalete['vesselvisit'].coloroutline;
	}
};
