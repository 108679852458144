<template>
	<v-layout data-app class="overflow-y-auto" :style="{ 'max-height': maxHeight }">
		<v-flex xs12>
			<v-data-table
				:headers="headers"
				:items="visits"
				:search="searchText"
				:height="maxHeight"
				class="operations-datatable elevation-1"
				disable-pagination
				hide-default-footer
				fixed-header
			>
				<template v-slot:[`header.two`]="{ header }" v-if="slotsShipCount">
					<v-tooltip top :z-index="200">
						<template v-slot:activator="{ on }">
							<div v-on="on">
								<v-badge bordered :color="getSlotColor(slotsShipCount[0])" :content="getSlotCount(slotsShipCount[0])">
									<span>{{ header.text }}</span>
								</v-badge>
							</div>
						</template>
						<span>{{ `${$t('operationsdatatable.slots.inboundmovements')}: ${slotsShipCount[0].entries}` }}</span>
						<br />
						<span>{{ `${$t('operationsdatatable.slots.outboundmovements')}: ${slotsShipCount[0].exits}` }}</span>
					</v-tooltip>
				</template>
				<template v-slot:[`header.eight`]="{ header }" v-if="slotsShipCount">
					<v-tooltip top :z-index="200">
						<template v-slot:activator="{ on }">
							<div v-on="on">
								<v-badge bordered :color="getSlotColor(slotsShipCount[1])" :content="getSlotCount(slotsShipCount[1])">
									<span>{{ header.text }}</span>
								</v-badge>
							</div>
						</template>
						<span>{{ `${$t('operationsdatatable.slots.inboundmovements')}: ${slotsShipCount[1].entries}` }}</span>
						<br />
						<span>{{ `${$t('operationsdatatable.slots.outboundmovements')}: ${slotsShipCount[1].exits}` }}</span>
					</v-tooltip>
				</template>
				<template v-slot:[`header.fourteen`]="{ header }" v-if="slotsShipCount">
					<v-tooltip top :z-index="200">
						<template v-slot:activator="{ on }">
							<div v-on="on">
								<v-badge bordered :color="getSlotColor(slotsShipCount[2])" :content="getSlotCount(slotsShipCount[2])">
									<span>{{ header.text }}</span>
								</v-badge>
							</div>
						</template>
						<span>{{ `${$t('operationsdatatable.slots.inboundmovements')}: ${slotsShipCount[2].entries}` }}</span>
						<br />
						<span>{{ `${$t('operationsdatatable.slots.outboundmovements')}: ${slotsShipCount[2].exits}` }}</span>
					</v-tooltip>
				</template>
				<template v-slot:[`header.twenty`]="{ header }" v-if="slotsShipCount">
					<v-tooltip top :z-index="200">
						<template v-slot:activator="{ on }">
							<div v-on="on">
								<v-badge bordered :color="getSlotColor(slotsShipCount[3])" :content="getSlotCount(slotsShipCount[3])">
									<span>{{ header.text }}</span>
								</v-badge>
							</div>
						</template>
						<span>{{ `${$t('operationsdatatable.slots.inboundmovements')}: ${slotsShipCount[3].entries}` }}</span>
						<br />
						<span>{{ `${$t('operationsdatatable.slots.outboundmovements')}: ${slotsShipCount[3].exits}` }}</span>
					</v-tooltip>
				</template>

				<template v-slot:body="{ items }">
					<tbody>
						<tr v-for="(item, indexP) in items" :key="indexP">
							<template>
								<td class="first-column">
									<div class="first-column__row">
										<div class="vessel-row">
											<div class="vessel-column vessel-column__left">
												<span class="vesselname">{{ item.vesselname }}</span>
											</div>
											<div class="vessel-column vessel-column__right">
												<!--
											<div
												class="vessel-column__icon"
												v-if="item.status !== 'CANCELLED' && item.status !== 'FINALIZED' && $store.getters.isCMAShippingLine"
											>
												<operations-visit-edit :visit="item"></operations-visit-edit>
											</div>
											-->
												<div
													v-if="$store.getters.hasFunctionality('READ_TECHNICALNAUTICALSERVICE')"
													class="vessel-column__icon"
													@click="changeVisibility(indexP, item)"
												>
													<v-tooltip top :z-index="200">
														<template v-slot:activator="{ on }">
															<v-icon v-on="on" small color="gray accent-4" style="cursor: pointer">fa fa-ship</v-icon>
														</template>
														<span>{{ $t('operationsdatatable.technicalnauticalservice') }}</span>
													</v-tooltip>
												</div>
												<div
													v-if="$store.getters.hasFunctionality('ETA_PREDICTION')"
													class="vessel-column__icon"
													@click="calculateEta(item)"
												>
													<v-tooltip top :z-index="200">
														<template v-slot:activator="{ on }">
															<v-icon v-on="on" small color="gray accent-4" style="cursor: pointer">fa fa-clock</v-icon>
														</template>
														<span>{{ $t('operationsdatatable.predicteta') }}</span>
													</v-tooltip>
												</div>
												<div class="vessel-column__icon" v-if="item.notifications">
													<v-tooltip top :z-index="200">
														<template v-slot:activator="{ on }">
															<v-icon v-on="on" small color="red accent-4">fas fa-bell</v-icon>
														</template>
														<span>{{ $t('operationsdatatable.notifications.enabled') }}</span>
													</v-tooltip>
												</div>
												<div class="vessel-column__icon" v-if="showMap(item) && $store.getters.isCMAShippingLine">
													<operations-visit-map-dialog :visit="item" />
												</div>
												<!--
											<div
												class="vessel-column__icon"
												v-if="!item.ata && item.status !== 'CANCELLED' && $store.getters.isCMAShippingLine"
											>
												<operations-visit-prediction :visit="item"></operations-visit-prediction>
											</div>
											-->
												<div class="vessel-column__icon" v-if="!item.ata && item.statusid !== 'AN' && item.atdfromport">
													<v-tooltip top :z-index="200">
														<template v-slot:activator="{ on }">
															<v-icon v-on="on" small>fas fa-ship</v-icon>
														</template>
														<span>{{
															$t('operationsdatatable.status.atdfrompreviousport') +
															item.atdfromport +
															getAMPM(item.atdfromport)
														}}</span>
													</v-tooltip>
												</div>
											</div>
										</div>
									</div>
									<div class="first-column__row">
										<span class="portcallnumber">{{ item.portcallnumber }}</span>
										<span :class="'statusname statusname_' + item.status.toLowerCase()">{{
											$t(`visit.statusid.${item.statusid.toLowerCase()}.title`)
										}}</span>
										<v-tooltip top v-if="item.statusname" :z-index="200">
											<template v-slot:activator="{ on }">
												<span v-on="on" class="statusname statusname_delayed">{{ `(${item.statusname})` }}</span>
											</template>
											<span>{{ item.statusdesc }}</span>
										</v-tooltip>
									</div>
									<div class="first-column__row" v-if="item.vesselstatus">
										<div class="vessel-row">
											<div class="vessel-column vessel-column__left">
												<v-tooltip top :z-index="200">
													<template v-slot:activator="{ on }">
														<span v-on="on">{{ item.vesselstatus }}</span>
													</template>
													<span>{{ item.vesselstatusdesc }}</span>
												</v-tooltip>
											</div>
										</div>
									</div>
									<div class="first-column__row">
										<div class="vessel-row">
											<div class="vessel-column vessel-column__left">
												<operations-visit-idle-time-dialog v-if="item.departureidletime" :visit="item" />
												<v-tooltip top v-else-if="item.phasedesc" :z-index="200">
													<template v-slot:activator="{ on }">
														<span
															v-on="on"
															class="statusname"
															:class="item.phasedescInAdvance ? 'statusname_inadvance' : 'statusname_delayed'"
															>{{ item.phase }}</span
														>
													</template>
													<span>{{ item.phasedesc }}</span>
												</v-tooltip>
												<span v-else>
													{{ item.phase }}
													<v-tooltip top :z-index="200" v-if="item.predictedetaWithAI">
														<template v-slot:activator="{ on }">
															<v-icon v-on="on" small class="ml-1">fas fa-head-side-brain</v-icon>
														</template>
														<span>{{ $t('operationsdatatable.phase.calculatedwithai') }}</span>
													</v-tooltip>
												</span>
											</div>
											<div class="vessel-column vessel-column__right">
												<!--
											<div
												class="vessel-column__icon"
												v-if="
													!item.ata &&
														item.status !== 'CANCELLED' &&
														$store.getters.isCMAShippingLine &&
														riskOfBeingPenalised(item)
												"
											>
												<v-tooltip top :z-index="200">
													<template v-slot:activator="{ on }">
														<v-icon v-on="on" small color="red accent-4">fas fa-exclamation-triangle</v-icon>
													</template>
													<span>{{ $t('operationsdatatable.riskofbeingpenalised') }}</span>
												</v-tooltip>
											</div>
											-->
												<div class="vessel-column__icon" v-if="item.berthcollision || item.estimatedarrivalviolated">
													<v-tooltip top :z-index="200">
														<template v-slot:activator="{ on }">
															<v-icon v-on="on" small color="red accent-4">fas fa-exclamation-circle</v-icon>
														</template>
														<span v-if="item.berthcollision">{{ $t('operationsdatatable.status.berthcollision') }}</span>
														<br v-if="item.berthcollision && item.estimatedarrivalviolated" />
														<span v-if="item.estimatedarrivalviolated">{{
															$t('operationsdatatable.status.estimatedarrivalviolated')
														}}</span>
													</v-tooltip>
												</div>
												<div
													class="vessel-column__icon"
													v-if="item.predictedeta && item.statusid !== 'IN' && item.statusid !== 'AN'"
												>
													<v-tooltip top :z-index="200">
														<template v-slot:activator="{ on }">
															<v-icon v-on="on" small color="orange accent-4">fas fa-broadcast-tower</v-icon>
														</template>
														<span>{{ $t('operationsdatatable.vesselaiscoverage') }}</span>
													</v-tooltip>
												</div>
												<div
													class="vessel-column__icon"
													v-if="
														item.chatLastMessage &&
														(!item.chatLastCheck || $moment(item.chatLastCheck).isBefore(item.chatLastMessage))
													"
												>
													<v-tooltip top :z-index="200">
														<template v-slot:activator="{ on }">
															<v-icon v-on="on" small color="red accent-4">fas fa-comment-dots</v-icon>
														</template>
														<span>{{ $t('operationsdatatable.pendingmessages') }}</span>
													</v-tooltip>
												</div>
											</div>
										</div>
									</div>
								</td>
								<td v-for="column in columns" :key="column.index" :class="getColumnClass(column)">
									<div class="visit-column-container">
										<div @click="showModal($event, item)">
											<!-- Visit and Stops ETA / ETD -->
											<operations-visit
												v-for="(item, index) in getTimelines(item, column, 'eta', 'etd')"
												:key="`etaetd_${column.index}_${index}`"
												:visitClass="item.class"
												:visitStyle="`width: ${item.width}%;`"
												:visitTooltip="item.tooltip"
											></operations-visit>
											<!-- Visit and Stops ATA / ATD -->
											<operations-visit
												v-for="(item, index) in getTimelines(item, column, 'ata', 'atd')"
												:key="`ataatd_${column.index}_${index}`"
												:visitClass="item.class"
												:visitStyle="`width: ${item.width}%;`"
												:visitTooltip="item.tooltip"
											></operations-visit>
											<!-- Visit and Stops events -->
											<operations-event
												v-for="(event, index) in getEvents(item, column)"
												:key="`event_${column.index}_${index}`"
												:eventName="event.name"
												:eventTooltip="event.tooltip"
												:style="event.style"
												class="operations-event-top"
											></operations-event>
										</div>

										<div v-for="service in getServiceArray(item.services)" @click="showModalServices($event, service, item)">
											<operations-visit
												v-if="technicalNauticalService[indexP] && item.tooltip"
												v-for="(item, indexT) in getServiceTime(service, column, 'eta', 'etd')"
												:key="indexT"
												:visitClass="item.class"
												:visitStyle="`width: ${item.width}%;  margin-left: ${item.marginleft}; margin-right: ${item.marginright}; `"
												:visitTooltip="item.tooltip"
											></operations-visit>
											<operations-visit
												v-if="technicalNauticalService[indexP] && item.tooltip"
												v-for="(item, indexT) in getServiceTime(service, column, 'ata', 'atd')"
												:key="indexT"
												:visitClass="item.class"
												:visitStyle="`width: ${item.width}%;  margin-left: ${item.marginleft}; margin-right: ${item.marginright}; `"
												:visitTooltip="item.tooltip"
											></operations-visit>
										</div>

										<div v-if="isCurrentHour(column)" class="current-hour-line" :style="getCurrentHourLineStyle(column)"></div>
									</div>
								</td>
							</template>
						</tr>
					</tbody>
				</template>
			</v-data-table>
		</v-flex>
		<v-dialog v-if="dialog" v-model="dialog" max-width="500px" persistent>
			<v-card>
				<v-card-title> {{ selectedVessel.vesselname }}-{{ selectedVessel.vesselmmsi }} </v-card-title>
				<v-card-text>
					<v-progress-linear color="success" v-show="stayProgress" indeterminate></v-progress-linear>
					<v-alert v-if="selectedVessel.calculatedEtaResponseError" dense type="error">
						{{ $t('operationsvisiteta.errorvelocity') }}
					</v-alert>
					<pui-date-field toplabel :label="$t('operationsvisiteta.vesseleta')" :value="selectedVessel.etaformatted" time disabled />
					<pui-date-field toplabel :label="$t('operationsvisiteta.eta')" :value="selectedVessel.calculatedEta" time disabled />
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="close()"> {{ $t('operationsvisiteta.close') }} </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import ChartDataMixin from '@/mixins/ChartDataMixin';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import OperationsVisit from './OperationsVisit.vue';
import OperationsEvent from './OperationsEvent.vue';
import OperationsVisitEdit from './OperationsVisitEdit.vue';
import OperationsVisitMapDialog from './OperationsVisitMapDialog.vue';
import OperationsVisitPrediction from './OperationsVisitPrediction.vue';
import OperationsVisitIdleTimeDialog from './OperationsVisitIdleTimeDialog.vue';

export default {
	name: 'OperationsVisitsDatatable',
	mixins: [ChartDataMixin, DateTimeMixin],
	components: {
		OperationsVisit,
		OperationsEvent,
		OperationsVisitEdit,
		OperationsVisitMapDialog,
		OperationsVisitPrediction,
		OperationsVisitIdleTimeDialog
	},
	props: {
		visits: {
			type: Array,
			required: true
		},
		columns: {
			type: Array,
			required: true
		},
		visitHour: {
			type: Object,
			required: true
		},
		searchText: {
			type: String,
			required: true
		},
		slotsShipCount: {
			type: Array
		},
		zoomIn: {
			type: Number
		}
	},
	data() {
		return {
			visitPositions: ['first', 'second', 'third', 'quarter', 'fifth', 'sixth', 'seventh', 'eighth'],
			headers: [
				{ text: '', align: 'left', sortable: false, value: 'vesselname' },
				{ text: 'portcallnumber', align: ' d-none', sortable: false, value: 'portcallnumber' }, // not visible but searchable
				{ text: 'status', align: ' d-none', sortable: false, value: 'status' }, // not visible but searchable
				{ text: 'phase', align: ' d-none', sortable: false, value: 'phase' }, // not visible but searchable
				{ text: 'statusname', align: ' d-none', sortable: false, value: 'statusname' } // not visible but searchable
			],
			maxHeight: window.innerHeight - 64 + 'px', // 64 = toolbar height
			dateTimeNow: new Date(),
			chartDataLoaded: false,
			chartData: null,
			chartDataVisit: null,
			technicalNauticalService: [],
			technicalNauticalServiceKey: 0,
			dataLoaded: false,
			dialog: false,
			selectedVessel: null,
			stayProgress: true
		};
	},
	created() {
		this.fillHeaders();
	},
	methods: {
		showMap(stop) {
			const statusValues = ['RQ', 'AC', 'AU', 'CO', 'WA', 'NR'];
			return stop.vesselimo && statusValues.includes(stop.statusid);
		},
		changeVisibility(indexP, service) {
			if (service.services.length == 0) {
				this.$puiNotify.info(this.$t('operationsdatatable.noServices'));
			} else {
				this.technicalNauticalService[indexP] = !this.technicalNauticalService[indexP];
			}
			this.$forceUpdate();
		},
		calculateEta(selectedVessel) {
			this.selectedVessel = selectedVessel;
			this.selectedVessel.calculatedEta = null;
			this.selectedVessel.etaformatted = null;
			this.selectedVessel.calculatedEtaResponseError = null;
			this.selectedVessel.etaformatted = new Date(this.getYearMonthDay(selectedVessel.eta, true));

			const params = {
				mmsi: selectedVessel.vesselmmsi,
				portId: selectedVessel.portid
				/*plannedEta: this.selectedVessel.plannedEta,
				dataWeather: this.dataWeather*/
			};

			/*this.waitingOneMinute = true;
			setTimeout(function() {
				self.waitingOneMinute = false;
			}, 60000);*/
			this.dialog = true;
			this.stayProgress = true;
			this.$puiRequests.getRequest(
				'/predictions/eta',
				params,
				(response) => {
					console.log(response);
					if (response && response.data) {
						this.selectedVessel.calculatedEta = response.data.eta_utc;
						this.selectedVessel.calculatedEtaResponseError = response.data.low_speed_warning;
					} else {
						this.$puiNotify.error(this.$puiI18n.t('operationsvisiteta.error'));
					}
					this.stayProgress = false;
				},
				(error) => {
					this.$puiNotify.error(this.$puiI18n.t('operationsvisiteta.error'));
					console.log(error);
					this.stayProgress = false;
				}
			);
			//this.dialog = false;
		},
		close() {
			this.selectedVessel.calculatedEtaResponseError = null;
			this.selectedVessel.calculatedEta = null;
			this.selectedVessel.etaformatted = null;
			this.selectedVessel = null;

			this.dialog = false;
		},
		fillHeaders() {
			for (let i = 0, length = this.columns.length; i < length; i++) {
				const hourHeader = { text: this.columns[i].label, align: 'center', sortable: false, value: this.columns[i].labelExt };
				if (this.isCurrentHour(this.columns[i])) {
					let nowHours = this.dateTimeNow.getHours();
					let nowMinutes = this.dateTimeNow.getMinutes();
					nowHours = (nowHours < 10 ? '0' : '') + nowHours;
					nowMinutes = (nowMinutes < 10 ? '0' : '') + nowMinutes;
					hourHeader.text = `${nowHours}:${nowMinutes}`;
					hourHeader.class = 'current-hour';
				}
				this.headers.push(hourHeader);
			}
		},
		getSlotCount(slot) {
			const count = slot ? slot.entries + slot.exits : 0;
			return count > 0 ? count : '0';
		},
		getSlotColor(slot) {
			return this.getSlotCount(slot) <= 3 ? '#36ba61' : this.getSlotCount(slot) > 6 ? '#ed5e4a' : '#fbbd40';
		},
		getColumnClass(column) {
			return {
				column: true,
				column__currentHour: this.isCurrentHour(column)
			};
		},
		isCurrentHour(column) {
			if (this.dateTimeNow.getTime() >= column.minDateMillis && this.dateTimeNow.getTime() < column.maxDateMillis) {
				return true;
			}
			return false;
		},
		getTimelines(visit, column, startProperty, endProperty) {
			const visitHourObj = this.visitHour[visit.id + '_' + column.index + startProperty + endProperty];
			if (!visitHourObj) {
				const clazz = {
					'visit-column__estimated': startProperty === 'eta' && endProperty === 'etd',
					'visit-column__real': startProperty === 'ata' && endProperty === 'atd'
				};
				return [{ type: 'none', width: 100, class: clazz }];
			}

			const result = [];
			for (var index = 0, visitPositionsLength = this.visitPositions.length; index < visitPositionsLength; index++) {
				const position = this.visitPositions[index];
				const clazz = {
					'visit-column__estimated': startProperty === 'eta' && endProperty === 'etd',
					'visit-column__real': startProperty === 'ata' && endProperty === 'atd'
				};
				if (!visitHourObj[position]) {
					if (result.length === 0) {
						result.push({ type: 'none', width: 12.5, class: clazz });
					} else {
						const last = result[result.length - 1];
						if (last.type === 'none') {
							last.width += 12.5;
						} else {
							result.push({ type: 'none', width: 12.5, class: clazz });
						}
					}
				} else {
					clazz[`content__${visitHourObj[position].type}_${startProperty}${endProperty}`] = true;
					clazz.content__leftRadius = visitHourObj[position].leftRadius;
					clazz.content__rightRadius = visitHourObj[position].rightRadius;
					const tooltip = visitHourObj[position].label;

					if (result.length === 0) {
						result.push({ type: visitHourObj[position].type, width: 12.5, class: clazz, tooltip: tooltip });
					} else {
						const last = result[result.length - 1];
						if (last.type === visitHourObj[position].type) {
							clazz.content__leftRadius = last.class.content__leftRadius;
							last.width += 12.5;
							last.class = clazz;
							last.tooltip = tooltip;
						} else {
							result.push({ type: visitHourObj[position].type, width: 12.5, class: clazz, tooltip: tooltip });
						}
					}
				}
			}
			return result;
		},
		getEvents(visit, column) {
			const visitHourObj = this.visitHour[visit.id + '_' + column.index];
			if (!visitHourObj) {
				return [];
			}

			const result = [];
			for (var index = 0, visitPositionsLength = this.visitPositions.length; index < visitPositionsLength; index++) {
				const position = this.visitPositions[index];
				if (visitHourObj[position]) {
					if (result.length === 0) {
						result.push({
							name: visitHourObj[position].event,
							tooltip: visitHourObj[position].label,
							position: visitHourObj[position].position,
							style: { left: '-4px' },
							left: -4
						});
					} else {
						const lastLeftValue = result[result.length - 1].left + 14;
						result.push({
							name: visitHourObj[position].event,
							tooltip: visitHourObj[position].label,
							position: visitHourObj[position].position,
							style: { left: `${lastLeftValue}px` },
							left: lastLeftValue
						});
					}
				}
			}

			if (result.length === 1) {
				const eventPosition = result[0].position;
				if (eventPosition === 'first' || eventPosition === 'second') {
					result[0].style = { left: '-4px' };
				} else if (eventPosition === 'third' || eventPosition === 'quarter') {
					result[0].style = { left: '10px' };
				} else if (eventPosition === 'fifth' || eventPosition === 'sixth') {
					result[0].style = { left: '24px' };
				} else if (eventPosition === 'seventh' || eventPosition === 'eighth') {
					result[0].style = { left: '38px' };
				}
			} else if (result.length === 2) {
				const firstEventPosition = result[0].position;
				const secondEventPosition = result[1].position;
				if (firstEventPosition === 'first' || firstEventPosition === 'second') {
					result[0].style = { left: '-4px' };
					if (
						secondEventPosition === 'first' ||
						secondEventPosition === 'second' ||
						secondEventPosition === 'third' ||
						secondEventPosition === 'quarter'
					) {
						result[1].style = { left: '10px' };
					} else if (secondEventPosition === 'fifth' || secondEventPosition === 'sixth') {
						result[1].style = { left: '24px' };
					} else if (secondEventPosition === 'seventh' || secondEventPosition === 'eighth') {
						result[1].style = { left: '38px' };
					}
				} else if (firstEventPosition === 'third' || firstEventPosition === 'quarter') {
					result[0].style = { left: '10px' };
					if (
						secondEventPosition === 'first' ||
						secondEventPosition === 'second' ||
						secondEventPosition === 'third' ||
						secondEventPosition === 'quarter' ||
						secondEventPosition === 'fifth' ||
						secondEventPosition === 'sixth'
					) {
						result[1].style = { left: '24px' };
					} else if (secondEventPosition === 'seventh' || secondEventPosition === 'eighth') {
						result[1].style = { left: '38px' };
					}
				} else if (
					firstEventPosition === 'fifth' ||
					firstEventPosition === 'sixth' ||
					firstEventPosition === 'seventh' ||
					firstEventPosition === 'eighth'
				) {
					result[0].style = { left: '24px' };
					result[1].style = { left: '38px' };
				}
			}

			return result;
		},
		getCurrentHourLineStyle(column) {
			if (column.hours === 1) {
				return { left: `${(this.dateTimeNow.getMinutes() * 100) / 60}%` };
			} else {
				const maxMillis = column.maxDateMillis - column.minDateMillis;
				const rightMillis = column.maxDateMillis - this.dateTimeNow.getTime();
				return { left: `${100 - (rightMillis * 100) / maxMillis}%` };
			}
		},
		showModal(event, visit) {
			const chartDataTemp = [];

			if (visit.eta && visit.etd) {
				const estimatedChartDataItems = this.getChartDataFromVisit(visit, 'eta', 'etd', visit.eta, visit.etd);
				//console.log('Estimated items: ', estimatedChartDataItems);
				Array.prototype.push.apply(chartDataTemp, estimatedChartDataItems);
			}
			if (visit.ata) {
				const realChartDataItems = this.getChartDataFromVisit(visit, 'ata', 'atd', visit.ata, visit.atd || this.getCurrentDateLocal(true));
				//console.log('Real items: ', realChartDataItems);
				Array.prototype.push.apply(chartDataTemp, realChartDataItems);
			}

			const eventChartDataItems = this.getChartDataEventsFromVisit(visit);
			//console.log('Events: ', eventChartDataItems);
			Array.prototype.push.apply(chartDataTemp, eventChartDataItems);

			this.chartDataVisit = visit;
			this.chartDataVisit.isService = false;

			this.chartData = chartDataTemp;
			this.chartDataLoaded = true;

			this.$puiEvents.$emit('operations-visitsDatatable-dataItemHit', this.chartDataVisit);
		},
		showModalServices(event, service, item) {
			console.log(service);
			this.chartDataVisit = service;
			this.chartDataVisit.visit = item;
			this.chartDataVisit.isService = true;
			this.chartDataLoaded = true;
			this.$puiEvents.$emit('operations-visitsDatatable-dataItemHit', this.chartDataVisit);
		},
		riskOfBeingPenalised(visit) {
			const localFormat = 'DD/MM/YYYY HH:mm';
			const momentFormat = 'YYYY-MM-DD HH:mm';

			const now = this.$dateTimeUtils.getLocalDateNow(momentFormat);
			const initMargin = this.$moment(visit.eta, localFormat).subtract(5, 'hours').format(momentFormat);
			const endMargin = this.$moment(visit.eta, localFormat).subtract(4, 'hours').format(momentFormat);

			if (this.$dateTimeUtils.isLocalDateSameOrAfter(now, initMargin) && this.$dateTimeUtils.isLocalDateSameOrBefore(now, endMargin)) {
				return true;
			}
			return false;
		},
		getAMPM(date) {
			try {
				return date.split(' ')[1].substring(0, 2) < 12 ? ' am' : ' pm';
			} catch (e) {}
			return '';
		},
		getServiceArray(items) {
			let services = items.reduce((acumulador, elemento) => {
				(acumulador[elemento['servicetypeid']] = acumulador[elemento['servicetypeid']] || []).push(elemento);
				return acumulador;
			}, {});

			let obj1 = null;
			let obj2 = null;
			let obj3 = null;
			if (services['1']) {
				obj1 = services['1'][0];

				for (let index = 1; index < services['1'].length; index++) {
					obj1['eta' + index] = services['1'][index].eta;
					obj1['etd' + index] = services['1'][index].etd;
					obj1['ata' + index] = services['1'][index].ata;
					obj1['atd' + index] = services['1'][index].atd;
				}
			}
			if (services['2']) {
				obj2 = services['2'][0];

				for (let index = 1; index < services['2'].length; index++) {
					obj2['eta' + index] = services['2'][index].eta;
					obj2['etd' + index] = services['2'][index].etd;
					obj2['ata' + index] = services['2'][index].ata;
					obj2['atd' + index] = services['2'][index].atd;
				}
			}
			if (services['3']) {
				obj3 = services['3'][0];

				for (let index = 1; index < services['3'].length; index++) {
					obj3['eta' + index] = services['3'][index].eta;
					obj3['etd' + index] = services['3'][index].etd;
					obj3['ata' + index] = services['3'][index].ata;
					obj3['atd' + index] = services['3'][index].atd;
				}
			}
			let result = [];

			if (obj1) result.push(obj1);

			if (obj2) result.push(obj2);
			if (obj3) result.push(obj3);
			return result;
		},
		getServiceTime(service, column, startProperty, endProperty) {
			let datesIni = [];
			let datesEnd = [];

			let contador = 0;

			for (let clave in service) {
				if (clave.startsWith('eta')) {
					contador++;
				}
			}

			let type;
			if (startProperty == 'eta' && endProperty == 'etd') {
			}
			let clazz = {
				'visit-column__estimated': startProperty === 'eta' && endProperty === 'etd',
				'visit-column__real': startProperty === 'ata' && endProperty === 'atd'
			};
			switch (service.servicetypeid) {
				case 1:
					type = this.$t('operationsdatatable.type.pilotage');
					if (startProperty == 'eta' && endProperty == 'etd') {
						datesIni.push(service.eta);
						datesEnd.push(service.etd);
						for (let i = 0; i <= contador; i++) {
							if (service['eta' + i]) {
								datesIni.push(service['eta' + i]);
							}
							if (service['etd' + i]) {
								datesEnd.push(service['etd' + i]);
							}
						}

						clazz = {
							'visit-column__estimated': startProperty === 'eta' && endProperty === 'etd',
							'visit-column__real': startProperty === 'ata' && endProperty === 'atd',
							content__Pilotage_eta: true
						};
					} else {
						datesIni.push(service.ata);
						datesEnd.push(service.atd);
						for (let i = 0; i <= contador; i++) {
							if (service['ata' + i]) {
								datesIni.push(service['ata' + i]);
							}
							if (service['atd' + i]) {
								datesEnd.push(service['atd' + i]);
							}
						}

						clazz = {
							'visit-column__estimated': startProperty === 'eta' && endProperty === 'etd',
							'visit-column__real': startProperty === 'ata' && endProperty === 'atd',
							content__Pilotage_ata: true
						};
					}

					break;
				case 2:
					type = this.$t('operationsdatatable.type.towage');

					if (startProperty == 'eta' && endProperty == 'etd') {
						datesIni.push(service.eta);
						datesEnd.push(service.etd);
						for (let i = 0; i <= contador; i++) {
							if (service['eta' + i]) {
								datesIni.push(service['eta' + i]);
							}
							if (service['etd' + i]) {
								datesEnd.push(service['etd' + i]);
							}
						}

						clazz = {
							'visit-column__estimated': startProperty === 'eta' && endProperty === 'etd',
							'visit-column__real': startProperty === 'ata' && endProperty === 'atd',

							content__Towage_eta: true
						};
					} else {
						datesIni.push(service.ata);
						datesEnd.push(service.atd);
						for (let i = 0; i <= contador; i++) {
							if (service['ata' + i]) {
								datesIni.push(service['ata' + i]);
							}
							if (service['atd' + i]) {
								datesEnd.push(service['atd' + i]);
							}
						}
						clazz = {
							'visit-column__estimated': startProperty === 'eta' && endProperty === 'etd',
							'visit-column__real': startProperty === 'ata' && endProperty === 'atd',

							content__Towage_ata: true
						};
					}

					break;
				case 3:
					type = this.$t('operationsdatatable.type.mooring');

					if (startProperty == 'eta' && endProperty == 'etd') {
						datesIni.push(service.eta);
						datesEnd.push(service.etd);
						for (let i = 0; i <= contador; i++) {
							if (service['eta' + i]) {
								datesIni.push(service['eta' + i]);
							}
							if (service['etd' + i]) {
								datesEnd.push(service['etd' + i]);
							}
						}

						clazz = {
							'visit-column__estimated': startProperty === 'eta' && endProperty === 'etd',
							'visit-column__real': startProperty === 'ata' && endProperty === 'atd',
							content__Mooring_eta: true
						};
					} else {
						datesIni.push(service.ata);
						datesEnd.push(service.atd);
						for (let i = 0; i <= contador; i++) {
							if (service['ata' + i]) {
								datesIni.push(service['ata' + i]);
							}
							if (service['atd' + i]) {
								datesEnd.push(service['atd' + i]);
							}
						}
						clazz = {
							'visit-column__estimated': startProperty === 'eta' && endProperty === 'etd',
							'visit-column__real': startProperty === 'ata' && endProperty === 'atd',
							content__Mooring_ata: true
						};
					}

					break;
				default:
					break;
			}
			const result = [];

			let daysPlus = 0;
			let daysMinus = 0;
			switch (this.zoomIn) {
				case 0:
					daysPlus = 0;
					daysMinus = 0;
					break;
				case 1:
					daysPlus = 0;
					daysMinus = 0;
					break;
				case 2:
					daysPlus = 0;
					daysMinus = 0;

					break;
				case 3:
					daysPlus = 0;
					daysMinus = 0;
					break;
				case 4:
					daysPlus = 0;
					daysMinus = 0;

					break;
				default:
					break;
			}
			let start = new Date(column.minDateMillis);
			let flagIniLeft = false;
			for (let index = 0; index < datesIni.length; index++) {
				if (
					new Date(datesIni[index]) >= start.setTime(start.getTime() - daysMinus * 60 * 60 * 1000) &&
					new Date(datesIni[index]) <= new Date(column.maxDateMillis)
				) {
					flagIniLeft = true;
				}
			}

			if (flagIniLeft) {
				clazz.content__leftRadius = true;
			}

			let end = new Date(column.maxDateMillis);
			let flagEndRight = false;
			for (let index = 0; index < datesEnd.length; index++) {
				if (
					new Date(datesEnd[index]) >= new Date(column.minDateMillis) &&
					new Date(datesEnd[index]) <= end.setTime(end.getTime() + daysPlus * 60 * 60 * 1000)
				) {
					flagEndRight = true;
				}
			}

			if (flagEndRight) {
				clazz.content__rightRadius = true;
			}

			let flagbetween = false;
			let dateDesIni = datesIni[0];
			let dateDesFi = datesEnd[0];
			for (let index = 0; index < datesIni.length; index++) {
				if (new Date(datesEnd[index]) >= new Date(column.minDateMillis) && new Date(column.maxDateMillis) >= new Date(datesIni[index])) {
					flagbetween = true;
					dateDesIni = datesIni[index];
					dateDesFi = datesEnd[index];
				}
			}

			if (flagbetween) {
				let flagTopeLeft = false;
				for (let index = 0; index < datesIni.length; index++) {
					if (new Date(column.minDateMillis) < new Date(datesIni[index]) && new Date(column.maxDateMillis) > new Date(datesIni[index])) {
						flagTopeLeft = true;
					}
				}

				let flagTopeRigtht = false;
				for (let index = 0; index < datesEnd.length; index++) {
					if (new Date(column.minDateMillis) < new Date(datesEnd[index]) && new Date(column.maxDateMillis) > new Date(datesEnd[index])) {
						flagTopeRigtht = true;
					}
				}

				if (flagTopeLeft) {
					result.push({
						type: 'Arrival',
						width: 50,
						class: clazz,
						tooltip:
							type +
							' ' +
							startProperty.toUpperCase() +
							':' +
							new Date(dateDesIni).toLocaleString() +
							', ' +
							endProperty.toUpperCase() +
							': ' +
							new Date(dateDesFi).toLocaleString(),
						marginleft: '50%'
					});
				} else if (flagTopeRigtht) {
					result.push({
						type: 'Arrival',
						width: 50,
						class: clazz,
						tooltip:
							type +
							' ' +
							startProperty.toUpperCase() +
							':' +
							new Date(dateDesIni).toLocaleString() +
							', ' +
							endProperty.toUpperCase() +
							': ' +
							new Date(dateDesFi).toLocaleString(),
						marginright: '50%'
					});
				} else {
					result.push({
						type: 'Arrival',
						width: 100,
						class: clazz,
						tooltip:
							type +
							' ' +
							startProperty.toUpperCase() +
							':' +
							new Date(dateDesIni).toLocaleString() +
							', ' +
							endProperty.toUpperCase() +
							': ' +
							new Date(dateDesFi).toLocaleString(),
						margin: '0%'
					});
				}
			} else {
				result.push({
					type: 'none',
					width: 100,
					class: {
						'visit-column__estimated': startProperty === 'eta' && endProperty === 'etd',
						'visit-column__real': startProperty === 'ata' && endProperty === 'atd'
					},
					tooltip: ''
				});
			}
			return result;
		}
	}
};
</script>

<style lang="postcss">
.operations-datatable {
	& table td + td {
		border-left: 1px solid #dddddd;
	}

	& thead {
		& th:not(:first-child) {
			color: #000000;
		}
		& th.current-hour {
			border-bottom: 4px solid #00a9d8 !important;
			padding: 0 12px !important;
		}
		& th.current-hour > span {
			color: #ffffff !important;
			background: #00a9d8 !important;
			border-radius: 12px !important;
			padding: 2px 6px 2px 6px !important;
		}
	}

	& .first-column {
		padding: 0 8px !important;
		&__row {
			margin-bottom: 5px;
		}
	}

	& .vessel-row:after {
		content: '';
		display: table;
		clear: both;
	}
	& .vessel-column {
		float: left;
		&__left {
			width: 85%;
		}
		&__right {
			width: 15%;
		}
		&__icon {
			display: inline-block;
			margin-right: 4px;
			& .v-icon {
				font-size: 15px !important;
			}
		}
	}

	& .column {
		padding: 0px !important;
		cursor: pointer;
		background: #f5f7f7;
		&__currentHour {
			background: #e5e5e5;
		}
	}

	& .vesselname {
		font-weight: 500;
	}
	& .portcallnumber {
		margin-right: 10px;
	}
	& .statusname {
		font-weight: bold;
		padding: 0 4px;
		background: #fdeeec;
		border-radius: 2px;
		&_delayed {
			color: #cb2b16;
		}
		&_cancelled {
			color: #cb2b16;
		}
		&_inadvance {
			color: #4fa439;
		}
	}

	& .visit-column-container {
		position: relative;
		min-height: 100%;
		& .current-hour-line {
			position: absolute;
			top: 0px;
			width: 2px;
			height: 100%;
			background-color: #00a9d8;
		}
	}

	& .operations-event-top {
		top: 31px;
	}

	@media screen and (max-width: 1600px) {
		& .first-column {
			padding: 0 8px !important;
			&__row {
				margin-bottom: 2px;
			}
		}

		& .operations-event-top {
			top: 45px;
		}
	}
}
</style>
