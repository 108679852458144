<template>
	<div class="map-container" ref="map">
		<map-popup :stop="featureHover"></map-popup>
		<map-zooms-control :currentmap="currentmap" />
		<map-legend-control :currentmap="currentmap" />
		<map-base-layer-control />
	</div>
</template>

<script>
/** Components */
import MapZoomsControl from '@/components/operations/map/sections/map/subcomponents/MapZoomsControlMap';
import MapPopup from '@/components/operations/map/sections/map/subcomponents/MapPopup';
import MapLegendControl from '@/components/operations/map/sections/map/subcomponents/MapLegendBody';
import MapBaseLayerControl from '@/components/operations/map/sections/map/subcomponents/MapBaseLayerControl';
import MapUpdateControl from '@/components/operations/map/sections/map/subcomponents/MapUpdateControl';

/** Constants */
import { INITIAL_ZOOM_VISITS, MAP_VISIT } from '@/components/operations/map/sections/map/subcomponents/mapVariables';
import { VESEEL_INFO_LAYER_ID } from '@/components/operations/map/constants/layers';
import ol2map from '@/components/operations/map/sections/map//subcomponents/ol2map';

/** Mixins */
import BaseLayersMixin from '@/mixins/BaseLayersMixin';
import StopsServiceMixin from '@/mixins/StopsServiceMixin';
import LayersActiveMixin from '@/mixins/LayersActiveMixin';
import TrackServiceMixin from '@/mixins/TrackServiceMixin';
import MovementSequenceMixin from '@/mixins/MovementSequenceMixin';

/** 3rd-party */
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Style, Stroke } from 'ol/style';
import Feature from 'ol/Feature';
import { fromLonLat } from 'ol/proj';
import { Point } from 'ol/geom';
import 'ol/ol.css';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import Attribution from 'ol/control/Attribution';

const attribution = new Attribution({
	collapsible: false
});

export default {
	name: 'OperationsVisitMapContent',

	mixins: [BaseLayersMixin, StopsServiceMixin, LayersActiveMixin, TrackServiceMixin, MovementSequenceMixin],

	components: {
		MapPopup,
		MapZoomsControl,
		MapLegendControl,
		MapBaseLayerControl,
		MapUpdateControl
	},

	props: {
		stopPosition: {
			type: Object,
			required: true
		},

		currentMap: {
			type: String,
			required: false,
			default: MAP_VISIT
		}
	},

	data() {
		return {
			currentmap: MAP_VISIT,
			featureHover: null
		};
	},

	created() {
		this.createMap();
	},

	mounted() {},

	computed: {},

	methods: {
		createMap() {
			this.$nextTick(() => {
				this.resolveMap();
			});
			this.$nextTick(() => {
				this.registerMapEvents();
			});
		},
		resolveMap() {
			const mapCanvas = this.$refs.map;

			const popup = document.getElementById('map-popup-overlay');
			this.overlay = new Overlay({
				zIndex: 9000, // Numero alto para que siempre este por encima
				element: popup,
				autoPan: false, // True: Ajustar al contenido para que se vea el popup
				autoPanAnimation: {
					duration: 250
				}
			});
			const lon = this.stopPosition.isScrapperServiceData ? this.stopPosition.longitude : this.stopPosition.lon;
			const lat = this.stopPosition.isScrapperServiceData ? this.stopPosition.latitude : this.stopPosition.lat;

			const feat = new Feature(new Point(fromLonLat([lon, lat])));
			const { isAisLastPos, vesselname, updateasdate, speed, destination } = this.stopPosition;
			feat.setProperties({ isAisLastPos, vesselname, updateasdate, speed, destination });

			const vesselLayer = new VectorLayer({
				source: new VectorSource({
					features: [feat]
				}),
				style: new Style({
					image: new Circle({
						radius: 5,
						fill: new Fill({ color: 'red' }),
						stroke: new Stroke({
							color: 'white',
							width: 2 // Ancho del borde del círculo
						})
					})
				}),
				properties: {
					id: VESEEL_INFO_LAYER_ID
				}
			});

			ol2map.init({
				// the map will be created using the 'map' ref
				controls: [attribution],
				target: mapCanvas,
				layers: [this.initialBaseLayerMap, vesselLayer],
				overlays: [this.overlay],
				// the map view will initially show the whole world
				view: new View({
					zoom: INITIAL_ZOOM_VISITS,
					center: fromLonLat([lon, lat])
				})
			});
		},

		/**
		 * Map events
		 */
		registerMapEvents() {
			var self = this;
			const mapCanvas = this.$refs.map;

			ol2map.getMapInstance().once('rendercomplete', function () {
				self.$store.commit('setMapAisLoaded', true);
			});

			ol2map.getMapInstance().on(
				'pointermove',
				(evt) => {
					const hit = ol2map.getMapInstance().forEachFeatureAtPixel(evt.pixel, (feature) => {
						return feature;
					});

					if (hit) {
						this.featureHover = hit.getProperties();
						mapCanvas.style.cursor = 'pointer';
						this.overlay.setPosition(evt.coordinate);
					} else {
						mapCanvas.style.cursor = '';
						this.overlay.setPosition(undefined);
						this.featureHover = null;
					}
				},
				{
					layerFilter: (layer) => layer instanceof VectorLayer
				}
			);

			ol2map.getMapInstance().on(
				'click',
				(evt) => {
					ol2map.getMapInstance().forEachFeatureAtPixel(evt.pixel, (feature) => {
						if (feature.getProperties().isAis && feature.getProperties().featureVisible) {
							var identifier = feature.getProperties().isAis ? feature.getProperties().mmsi : feature.getProperties().id;
							this.openPanel(identifier, feature.getProperties());
						} else {
							if (feature.getProperties().featureVisible && feature.getProperties().vesselimo) {
								var identifier = feature.getProperties().id;
								this.openPanel(identifier, feature.getProperties());
							}
						}
					});
				},
				{
					layerFilter: (layer) => layer instanceof VectorLayer
				}
			);
		}
	},
	watch: {}
};
</script>

<style scoped>
.map-container {
	width: 100%;
	height: 50vh;
	min-height: 500px;
	position: relative;
}
.ol-attribution {
	left: 0px;
}
</style>
