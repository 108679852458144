import { Fill, Style, Stroke, Circle } from 'ol/style';
import Text from 'ol/style/Text';
import { Point } from 'ol/geom';
import colorUtils from './colorUtils';

const aisStyleFunction = function (feature, resolution) {
	var lonCenter = feature.get('lon');
	var latCenter = feature.get('lat');
	var center = [lonCenter, latCenter];

	var boatStyle = new Style({
		stroke: new Stroke({
			color: createStrokeStyle(feature),
			width: 0.75
		}),
		fill: createFillStyle(feature),
		text: feature.get('featureVisible') ? createTextStyle(feature, resolution) : null
	});

	var styles = [boatStyle];

	if (center && feature.get('featureVisible') && resolution < 1.5) {
		var pointStyle = new Style({
			geometry: new Point(center),
			image: new Circle({
				radius: 1.5,
				fill: new Fill({
					color: '#0C1A36'
				}),
				stroke: new Stroke({
					color: '#0C1A36',
					width: 1
				})
			})
		});
		styles.push(pointStyle);
	}
	return styles;
};

const getVesselText = function (feature, resolution) {
	let text = '';
	if (resolution < 1.5) {
		text = feature.get('vesselname');
	}
	return text;
};

const createTextStyle = function (feature, resolution) {
	const rotation = feature.get('alfa') > Math.PI / 2 && feature.get('alfa') < Math.PI ? feature.get('alfa') + Math.PI : feature.get('alfa');
	return new Text({
		text: getVesselText(feature, resolution),
		rotation,
		stroke: new Stroke({
			color: 'white',
			width: 4
		}),
		overflow: true,
		font: '8px sans-serif'
	});
};

const createStrokeStyle = function (feature) {
	if (!feature.get('featureVisible')) {
		return 'rgba(0, 0, 0, 0)';
	}
	return colorUtils.getColorOutlineOperations(feature);
};

const createFillStyle = function (feature) {
	if (!feature.get('featureVisible')) {
		return new Fill({
			color: 'rgba(0, 0, 0, 0)'
		});
	}
	return new Fill({
		color: colorUtils.getColorOperations(feature)
	});
};

export default aisStyleFunction;
