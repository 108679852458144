import ScraperService from '@/services/scraper.service.js';
export default {
	data() {
		return {
			dateFormat: this.$store.getters.dateFormat,
			timeFormat: this.$store.getters.timeFormat
		};
	},
	methods: {
		async getInitialAISVessels() {
			const response = await this.$puiRequests.getRequest(
				'/operations/getShipsInitialLoad?portAuthorityId=' + window.localStorage.getItem('workingPortAuthorityId')
			);
			const stops = [];
			for (let i = 0, stopsLength = response.data.length; i < stopsLength; i++) {
				const stop = JSON.parse(JSON.stringify(response.data[i]));
				stop.otherVessel = !stop.isService && !stop.portCallNumber;
				stop.otherVesselServices = stop.isService && !stop.isPilot && stop.isTug;

				stops.push(stop);
			}
			return stops;
		},

		async getAISVesselsPosition(stop) {
			const response = await this.$puiRequests.getRequest('/aisvessel/getAISVesselPosition', { mmsi: stop.vesselmmsi });

			var stopvessel = response.data && response.data != '' ? JSON.parse(JSON.stringify(response.data)) : null;
			if (stopvessel) {
				stopvessel.vesselname = stop.vesselname;
			} else {
				// Buscamos la posicion en el Scrapper.
				stopvessel = await ScraperService.getVesselInfo(stop);
				stopvessel.isScrapperServiceData = true;
			}
			stopvessel.isAisLastPos = true;

			return stopvessel;
		}
	}
};
