<template>
	<v-menu v-if="$store.getters.hasFunctionality('CREATE_SUBSCRIPTION')" v-model="menu" :close-on-content-click="false" offset-y>
		<template v-slot:activator="{ on }">
			<v-btn icon ripple text v-on="on">
				<v-icon v-if="!model || !model.visit">far fa-bell</v-icon>
				<v-icon v-else color="red accent-4">fas fa-bell</v-icon>
			</v-btn>
		</template>

		<v-card class="operations-visit-detail-notifications">
			<v-container v-if="model" class="mb-12">
				<v-layout wrap align-center>
					<v-flex xs10>
						<span class="notif__title">{{ $t('operationsvisitdetailnotifications.notifications') }}</span>
					</v-flex>
					<v-flex xs2>
						<v-switch v-model="notifications"></v-switch>
					</v-flex>
				</v-layout>

				<v-layout wrap class="pt-3">
					<v-flex xs12>
						<span class="notif__subtitle">{{ $t('operationsvisitdetailnotifications.events') }}</span>
					</v-flex>
					<v-flex xs6 v-for="(event, index) in events" :key="index">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-checkbox v-model="model[event.property]" :readonly="!notifications" color="primary" :ripple="false" hide-details>
									<span slot="label" v-on="on">{{ event.name }}</span>
								</v-checkbox>
							</template>
							<span>{{ event.description }}</span>
						</v-tooltip>
					</v-flex>
				</v-layout>

				<v-layout wrap class="pt-3">
					<v-flex xs12>
						<span class="notif__subtitle">{{ $t('operationsvisitdetailnotifications.status.title') }}</span>
					</v-flex>
					<v-flex xs12 mt-3>
						<v-checkbox
							:label="$t('operationsvisitdetailnotifications.status.cancelled')"
							:readonly="!notifications"
							v-model="model.cancelled"
							color="primary"
							:ripple="false"
							hide-details
						></v-checkbox>
					</v-flex>
					<v-flex xs12 mt-3>
						<v-checkbox
							:label="$t('operationsdatatable.status.delayed.nowGreaterThanEta')"
							:readonly="!notifications"
							v-model="model.delayeta"
							color="primary"
							:ripple="false"
							hide-details
						></v-checkbox>
					</v-flex>
					<v-flex xs12 mt-3 v-if="false">
						<v-checkbox
							:label="$t('operationsdatatable.status.delayed.etcGreaterThanEtd')"
							:readonly="!notifications"
							v-model="model.delayetc"
							color="primary"
							:ripple="false"
							hide-details
						></v-checkbox>
					</v-flex>
					<v-flex xs12 mt-10 v-if="false">
						<v-checkbox
							:label="$t('operationsvisitdetailnotifications.visitEtaManual')"
							:readonly="!notifications"
							v-model="model.visitetamanual"
							color="primary"
							:ripple="false"
							hide-details
						></v-checkbox>
					</v-flex>
				</v-layout>
			</v-container>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn text @click="menu = false">{{ $t('form.cancel') }}</v-btn>
				<v-btn color="primary" text @click="saveSubscription()">{{ $t('form.save') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>
import EventsMixin from '@/mixins/EventsMixin';

export default {
	mixins: [EventsMixin],
	props: {
		visit: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			modelName: 'subscription',
			modelConfig: null,
			modelTemplate: null,
			model: null,
			menu: false,
			checkAll: true,
			notifications: false,
			events: []
		};
	},
	computed: {
		onPuiModelsLoadedAndSaved() {
			return this.$store.state.modelsLoadedAndSaved;
		}
	},
	watch: {
		onPuiModelsLoadedAndSaved() {
			this.onReady();
		},
		notifications(newVal) {
			if (!newVal) {
				for (const property in this.model) {
					if (property === 'usr' || property === 'visit') {
						continue;
					}
					this.model[property] = false;
				}
			} else {
				if (!this.checkAll) {
					this.checkAll = true;
					return;
				}
				for (const property in this.model) {
					if (property === 'usr' || property === 'visit') {
						continue;
					}
					this.model[property] = true;
				}
			}
		}
	},
	mounted() {
		this.fillEvents();
		this.modelConfig = this.$store.getters.getModelByName(this.modelName);
		console.log(this.modelConfig);
		if (this.modelConfig) {
			this.onReady();
		}
	},
	methods: {
		fillEvents() {
			const internalEvents = [];

			for (let i = 0, eventsLength = this.visitEvents.length; i < eventsLength; i++) {
				const event = this.visitEvents[i];
				const eventName = this.allEventsName[event];
				const eventDescription = this.allEventsDescription[event];
				const eventProperty = `visit${event}`;
				//internalEvents.push({ name: eventName, description: eventDescription, property: eventProperty });
			}

			for (let j = 0, stopEvents = this.stopEvents.length; j < stopEvents; j++) {
				const event = this.stopEvents[j];
				const eventName = this.allEventsName[event];
				const eventDescription = this.allEventsDescription[event];
				const eventProperty = `stop${event}`;
				const index = internalEvents.length - 1;
				if (event == 'fla' || event == 'lla') {
					internalEvents.splice(index, 0, { name: eventName, description: eventDescription, property: eventProperty });
				}
			}

			this.events = internalEvents;
		},
		onReady() {
			if (this.$store.getters.hasFunctionality('CREATE_SUBSCRIPTION')) {
				this.getSubscription();
				console.log('aaaaaaaaa');
			}
		},
		getSubscription() {
			const params = {
				usr: this.$store.getters.getUser,
				visit: this.visit.id
			};
			this.$puiRequests.getRequest(
				this.modelConfig.url.get,
				params,
				(response) => {
					this.model = JSON.parse(JSON.stringify(response.data));
					this.modelTemplate = response.data;

					if (this.modelTemplate['usr'] || this.modelTemplate['visit']) {
						this.checkAll = false;
						this.notifications = true;
						for (const property in this.modelTemplate) {
							if (property === 'usr' || property === 'visit') {
								this.modelTemplate[property] = null;
							} else {
								this.modelTemplate[property] = false;
							}
						}
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		saveSubscription() {
			if (!this.model.visit && !this.model.usr) {
				if (this.notifications) {
					this.visit.notifications = true;
					this.insertSubscription();
				}
			} else {
				if (this.notifications) {
					this.updateSubscription();
				} else {
					this.visit.notifications = false;
					this.deleteSubscription();
				}
			}
			this.menu = false;
		},
		insertSubscription() {
			this.model.usr = this.$store.getters.getUser;
			this.model.visit = this.visit.id;
			this.$puiRequests.postRequest(
				this.modelConfig.url.insert,
				this.model,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		updateSubscription() {
			this.$puiRequests.putRequest(
				this.modelConfig.url.update,
				this.model,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		deleteSubscription() {
			const params = {
				usr: this.model.usr,
				visit: this.model.visit
			};
			this.$puiRequests.deleteRequest(
				this.modelConfig.url.delete,
				null,
				() => {
					this.model = JSON.parse(JSON.stringify(this.modelTemplate));
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				},
				params
			);
		}
	}
};
</script>

<style lang="postcss">
.operations-visit-detail-notifications {
	& span {
		&.notif__title {
			font-weight: 600;
			font-size: 15px;
		}
		&.notif__subtitle {
			font-weight: 500;
			font-size: 14px;
			color: #303e5a;
		}
	}
	& .v-input .v-input__control .v-input__slot label {
		position: initial !important;
	}
}
</style>
