<template>
	<!-- Map Legend Collapsible -->
	<map-legend-control>
		<template>
			<v-expand-transition>
				<v-card flat max-height="290px" class="d-flex flex-column" v-if="legendExpanded">
					<div class="d-flex flex-column pa-2" style="overflow-y: auto; max-width: 100%">
						<div v-if="this.isCurrentMapAis" class="maplegendcontrol__row-title mb-0">{{ $t('legend.vessel') }}</div>
						<!-- Layers legend -->
						<div class="ml-1">
							<div v-for="(layer, i) in layersIcons" :key="i" class="d-flex align-center py-1">
								<svg-icon group="tabs" :icon="layer.iconlegend" :width="20"></svg-icon>
								<div class="maplegendcontrol__row-element ml-1">{{ layer.title }}</div>
							</div>
						</div>
						<div v-if="isMapStatusStyleVisualization && !isMapVisit">
							<div class="maplegendcontrol__row-title mb-0">{{ $t('legend.status') }}</div>
							<item
								v-for="(legendIcon, index) in statusItemsData.items"
								v-show="legendIcon.visiblemaps"
								:key="index"
								v-bind="legendIcon"
								:shape="legendIcon.shape"
								:color="legendIcon.colorhex"
								:coloroutline="legendIcon.colorhexoutline"
							>
							</item>
						</div>
						<div v-if="isMapTypeStyleVisualization && !isMapVisit">
							<div class="maplegendcontrol__row-title mb-0">{{ $t('legend.vesseltype') }}</div>
							<item
								v-for="(legendIcon, index) in typeItemsData.items"
								:key="index"
								v-bind="legendIcon"
								:shape="legendIcon.shape"
								:color="legendIcon.color"
								:coloroutline="legendIcon.coloroutline"
							>
							</item>
						</div>
						<div v-if="isMapOperationsStyleVisualization && !isMapVisit">
							<div class="maplegendcontrol__row-title mb-0">{{ $t('legend.operationstype') }}</div>
							<item
								v-for="(legendIcon, index) in operationsItemsData.items"
								:key="index"
								v-bind="legendIcon"
								:shape="legendIcon.shape"
								:color="legendIcon.color"
								:coloroutline="legendIcon.coloroutline"
							>
							</item>
						</div>
						<div v-if="isMapVisit">
							<div class="maplegendcontrol__row-title mb-0">{{ $t('legend.lastpos') }}</div>
							<item
								v-for="(legendIcon, index) in aisPositionItemsData.items"
								:key="index"
								v-bind="legendIcon"
								:shape="legendIcon.shape"
								:color="legendIcon.color"
								:coloroutline="legendIcon.coloroutline"
							>
							</item>
						</div>
					</div>

					<v-divider />

					<!-- Show Full Legend -->
					<!-- <v-card-actions class="d-flex align-center justify-center py-1">
						<v-btn @click="openModal" color="var(--eiffel-accent)" small text style="font-size: 0.9rem" class="font-weight-500">
							<v-icon left size="0.9rem" class="ma-0 mr-1">fal fa-list</v-icon>
							<span style="font-family: var(--font-family)">
								{{ $t('legend.showall') }}
							</span>
						</v-btn>
					</v-card-actions> -->
				</v-card>
			</v-expand-transition>
			<!-- Modal Dialog Full Legend -->
			<!-- <map-legend-dialog v-model="modalOpened" /> -->
		</template>
	</map-legend-control>
</template>

<script>
import MapLegendDialog from './MapLegendDialog';
import MapLegendControl from './MapLegendControl';
import SvgIcon from '@/components/icon/SvgIcon';
import Item from './MapLegendDialogItem';

import LegendItemsMixin from '@/mixins/LegendItemsMixin';
import { debounce } from 'lodash';

/** Constants */
import { MAP_AIS_ID, MAP_VISIT, STATUS_VIS, TYPE_VIS, OPERATIONS_VIS } from '@/components/operations/map/sections/map/subcomponents/mapVariables';

export default {
	name: 'maplegendbody',

	components: {
		MapLegendDialog,
		MapLegendControl,
		SvgIcon,
		Item
	},

	props: {
		currentmap: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			layersIcons: [],
			modalOpened: false,
			handleUpdate: debounce(this._handleUpdate, 250),
			updateLayersIcons: debounce(this._updateLayersIcons, 250)
		};
	},

	mixins: [LegendItemsMixin],

	computed: {
		legendExpanded() {
			return this.$store.state.mappanelais.map.legendExpanded;
		},

		layers() {
			return this.$store.getters.layers;
		},

		isMapStatusStyleVisualization() {
			if (this.isCurrentMapAis) {
				return this.$store.getters.getMapAisStyleVisualization === STATUS_VIS;
			} else {
				return this.$store.getters.getMapPeriodsStyleVisualization === STATUS_VIS;
			}
		},
		isMapTypeStyleVisualization() {
			if (this.isCurrentMapAis) {
				return this.$store.getters.getMapAisStyleVisualization === TYPE_VIS;
			} else {
				return this.$store.getters.getMapPeriodsStyleVisualization === TYPE_VIS;
			}
		},
		isMapOperationsStyleVisualization() {
			if (this.isCurrentMapAis) {
				return this.$store.getters.getMapAisStyleVisualization === OPERATIONS_VIS;
			} else {
				return this.$store.getters.getMapPeriodsStyleVisualization === OPERATIONS_VIS;
			}
		},

		isCurrentMapAis() {
			return this.currentmap == MAP_AIS_ID;
		},

		isMapVisit() {
			return this.currentmap == MAP_VISIT;
		},

		operationsItemsData() {
			var dataOperationsItems = this.operationsItems;
			dataOperationsItems.items = Object.values(this.$store.getters.stopsOperationsValues);
			return dataOperationsItems;
		},

		statusItemsData() {
			var dataStatusItems = this.statusItems;
			dataStatusItems.items = this.$store.getters.stopsStatusValues;
			dataStatusItems.items.forEach((ele) => {
				ele.text = ele.stopstatusname;
				ele.shape = 'point';
			});
			dataStatusItems.items['default'] = this.defaultItem;
			//dataStatusItems.items['modified'] = this.modifiedItem; // Para añadir en la leyenda el color de un barco modificado
			return dataStatusItems;
		},

		typeItemsData() {
			var dataTypeItems = this.vesselTypeItems;
			dataTypeItems.items = this.$store.getters.stopsTypeValues;
			dataTypeItems.items['default'] = this.defaultItem;
			//dataStatusItems.items['modified'] = this.modifiedItem; // Para añadir en la leyenda el color de un barco modificado
			return dataTypeItems;
		},

		aisPositionItemsData() {
			var dataItems = this.stopPositionItems;
			dataItems.items.push(this.stopPositionItem);
			return dataItems;
		}
	},

	mounted() {},

	destroyed() {},

	methods: {
		openModal() {
			this.$emit('update', false);
			this.modalOpened = true;
		},

		closeDialog() {
			this.modalOpened = false;
		},

		_handleUpdate() {
			if (!this.legendExpanded) {
				return;
			}

			this._updateLayersIcons();
		},

		async _updateLayersIcons() {
			this.layersIcons = [];
			if (this.isCurrentMapAis) {
				var aisIcon = {
					iconlegend: 'tabais',
					title: 'AIS'
				};
				this.layersIcons.push(aisIcon);
			}
		}
	},

	watch: {
		legendExpanded() {
			if (this.legendExpanded) {
				this._handleUpdate();
			}
		}
	}
};
</script>

<style lang="postcss"></style>
