<template>
	<div class="operations-legend">
		<v-layout justify-center>
			<operations-legend-block
				:title="$t('operationslegend.arrival')"
				:estimatedColor="arrivalEstimatedColor"
				:realColor="arrivalRealColor"
			></operations-legend-block>
			<operations-legend-block
				:title="$t('operationslegend.cargo')"
				:estimatedColor="cargoEstimatedColor"
				:realColor="cargoRealColor"
			></operations-legend-block>
			<operations-legend-block
				:title="$t('operationslegend.anchored')"
				:estimatedColor="anchoredEstimatedColor"
				:realColor="anchoredRealColor"
			></operations-legend-block>
			<operations-legend-block
				:title="$t('operationslegend.departure')"
				:estimatedColor="departureEstimatedColor"
				:realColor="departureRealColor"
			></operations-legend-block>

			<operations-legend-block
				v-if="$store.getters.hasFunctionality('READ_TECHNICALNAUTICALSERVICE')"
				:title="$t('operationsdatatable.type.mooring')"
				:estimatedColor="mooringEstimatedColor"
				:realColor="mooringRealColor"
			></operations-legend-block>
			<operations-legend-block
				v-if="$store.getters.hasFunctionality('READ_TECHNICALNAUTICALSERVICE')"
				:title="$t('operationsdatatable.type.pilotage')"
				:estimatedColor="pilotageEstimatedColor"
				:realColor="pilotageRealColor"
			></operations-legend-block>
			<operations-legend-block
				v-if="$store.getters.hasFunctionality('READ_TECHNICALNAUTICALSERVICE')"
				:title="$t('operationsdatatable.type.towage')"
				:estimatedColor="towageEstimatedColor"
				:realColor="towageRealColor"
			></operations-legend-block>
		</v-layout>
	</div>
</template>

<script>
import OperationsLegendBlock from './OperationsLegendBlock';

export default {
	components: {
		OperationsLegendBlock
	},
	data() {
		return {
			arrivalEstimatedColor: '#F1BCF2',
			arrivalRealColor: '#B758D9',
			cargoEstimatedColor: '#BADD8E',
			cargoRealColor: '#4FA439',
			anchoredEstimatedColor: '#AFDEF8',
			anchoredRealColor: '#1F98EF',
			departureEstimatedColor: '#FDDEB6',
			departureRealColor: '#EF7217',
			mooringEstimatedColor: '#ffd0ec',
			mooringRealColor: '#fb6cc1',
			pilotageEstimatedColor: '#fde9bf',
			pilotageRealColor: '#fbbd40',
			towageEstimatedColor: '#f8c5be',
			towageRealColor: '#ed5e4a'
		};
	}
};
</script>
